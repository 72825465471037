import { editorContextStore } from "@/contexts/editor-context";
import { EditorAssetContentType, EditorAssetExtension } from "@/core/common/types";
import { isDataURL, isValidFirebaseStoragePath } from "@/core/utils/string-utils";
import { Assets } from "../controllers/assets";
import { debugError } from "./print-utilts";

export function getEditorAssetExtension(contentType: EditorAssetContentType) {
  return EditorAssetExtension[contentType];
}

export function isEditorAssetContentTypeValid(
  contentType: string,
): contentType is EditorAssetContentType {
  return Object.keys(EditorAssetExtension).findIndex((t) => t === contentType) > -1;
}

export function isHeicImageType(contentType: string) {
  return contentType.toLowerCase().includes("image/heic");
}

export function getDataUrlFromBlob(blob: Blob | undefined | null) {
  return new Promise<string | undefined | null>((resolve, reject) => {
    if (!blob) {
      return resolve(undefined);
    }
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target?.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

async function fetchDataUrlFromUrl(url: string) {
  const object = await fetch(url).then((r) => r.blob());
  return getDataUrlFromBlob(object);
}

export async function getDataUrlFromString(data: string) {
  try {
    if (!data) {
      debugError("Cannot retrieve data url from string because the input data is invalid.");
      return "";
    }
    if (isDataURL(data)) {
      return data;
    } else if (isValidFirebaseStoragePath(data)) {
      const storageManager = editorContextStore.getState()?.storageManager;

      const url = await Assets.loadAssetFromPath({
        path: data,
        storageManager,
      });

      return await fetchDataUrlFromUrl(url);
    } else {
      return await fetchDataUrlFromUrl(data);
    }
  } catch (error) {
    debugError("Error fetching data url from url: ", error);
    return "";
  }
}
