import { editorContextStore, IEditorContext } from "@/contexts/editor-context";
import { getTeamQuotasFromContext } from "@/core/utils/team-quota-utils";

export function getUserQuotasFromEditorContext(editorContextState: IEditorContext) {
  const { userQuotas } = editorContextState;

  return getTeamQuotasFromContext(editorContextState) || userQuotas;
}

export function getTeamOrUserQuotas() {
  return getUserQuotasFromEditorContext(editorContextStore.getState());
}
