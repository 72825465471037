import { SecondaryButtonClassNameInactive } from "@/components/constants/class-names";
import { ImageComponentPrimitive } from "@/components/utils/image";
import { classNames } from "@/core/utils/classname-utils";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import React, { useEffect } from "react";
import styles from "./loading-card.module.css";

export type PresetImageGridItemPropsAddItemHandler = (params: {
  url: string;
  event?: Event;
  caption?: string;
}) => void;

export type PresetImageGridItemProps = AspectRatio.AspectRatioProps &
  React.RefAttributes<HTMLDivElement> & {
    url: string;
    name?: string;
    onAddItem: PresetImageGridItemPropsAddItemHandler;
  };

export const PresetImageGridItem = React.memo(
  React.forwardRef(
    (
      {
        url,
        name = "asset",
        className = SecondaryButtonClassNameInactive,
        onAddItem,
        onClick,
        onDragEnd,
        children,
        ...props
      }: PresetImageGridItemProps,
      forwardedRef: React.ForwardedRef<HTMLDivElement>,
    ) => {
      const [isImageLoaded, setImageLoaded] = React.useState(false);

      useEffect(() => {
        if (!url) {
          setImageLoaded(false);
        }
      }, [url]);

      return (
        <AspectRatio.Root
          ref={forwardedRef}
          ratio={1}
          draggable
          className={classNames(
            styles.Card,
            isImageLoaded ? "" : styles.IsLoading,
            "relative items-center justify-center",
            className ?? "",
          )}
          onClick={(event) => {
            onAddItem({
              url,
            });
            onClick?.(event);
          }}
          onDragEnd={(event) => {
            onAddItem({
              url,
              event: event.nativeEvent,
            });
            onDragEnd?.(event);
          }}
          {...props}
        >
          {url && (
            <ImageComponentPrimitive
              alt={name}
              src={url}
              style={{
                display: isImageLoaded ? "block" : "none",
              }}
              className="max-h-full max-w-full rounded-sm"
              isImageLoaded={isImageLoaded}
              setImageLoaded={setImageLoaded}
            />
          )}
          {children}
        </AspectRatio.Root>
      );
    },
  ),
);

export function PresetImageGridContainer({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div className={classNames("grid grid-cols-3 gap-2", className ?? "")} {...props}>
      {children}
    </div>
  );
}
