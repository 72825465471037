import { getUpdaterFunction, SetEditorStateFunction } from "@/contexts/editor-context-utils";
import { noop } from "lodash";
import { StateUpdater } from "./utils";

export enum PricingConfigVersion {
  Oct9th2024 = "oct-09-2024",
}

export function isPricingConfigVersion(version: any): version is PricingConfigVersion {
  return Object.values(PricingConfigVersion).includes(version);
}

export interface CustomModelPricingConfig {
  dollarPerCustomModelTrainingCredit: number;
  dollarPerCustomModelPredictionCredit: number;
  estimatedCustomModelTrainingCreditsPerRun: number;
  estimatedCustomModelPredictionCreditsPerRun: number;
}

export function isCustomModelPricingConfig(obj: any): obj is CustomModelPricingConfig {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj?.estimatedCustomModelTrainingCreditsPerRun === "number"
  );
}

export interface VideoGenerationPricingConfig {
  dollarPerVideoGenerationCredit: number;
  creditsPerVideoGenerationRun: number;
}

export function isVideoGenerationPricingConfig(obj: any): obj is VideoGenerationPricingConfig {
  return (
    typeof obj === "object" && obj !== null && typeof obj?.creditsPerVideoGenerationRun === "number"
  );
}

export interface PricingConfigBase extends CustomModelPricingConfig, VideoGenerationPricingConfig {}

export interface PricingConfig extends PricingConfigBase {
  version: PricingConfigVersion;
}

export function isPricingConfig(obj: any): obj is PricingConfig {
  return (
    isCustomModelPricingConfig(obj) &&
    isVideoGenerationPricingConfig(obj) &&
    "version" in obj &&
    isPricingConfigVersion((obj as any).version)
  );
}

export interface PricingConfigEditorState {
  pricingConfig: PricingConfig;
  setPricingConfig: (value: StateUpdater<PricingConfig>) => void;
}

export function getDummyPricingConfig(): PricingConfigEditorState {
  return {
    pricingConfig: {
      creditsPerVideoGenerationRun: 1,
      estimatedCustomModelTrainingCreditsPerRun: 3600,
      dollarPerCustomModelPredictionCredit: 0.002,
      estimatedCustomModelPredictionCreditsPerRun: 15,
      dollarPerCustomModelTrainingCredit: 0.001388888888888889,
      dollarPerVideoGenerationCredit: 0.5,
      version: PricingConfigVersion.Oct9th2024,
    },
    setPricingConfig: noop,
  };
}

export function getDefaultPricingConfig(set: SetEditorStateFunction): PricingConfigEditorState {
  return {
    pricingConfig: {
      creditsPerVideoGenerationRun: 1,
      estimatedCustomModelTrainingCreditsPerRun: 3600,
      dollarPerCustomModelPredictionCredit: 0.002,
      estimatedCustomModelPredictionCreditsPerRun: 15,
      dollarPerCustomModelTrainingCredit: 0.001388888888888889,
      dollarPerVideoGenerationCredit: 0.5,
      version: PricingConfigVersion.Oct9th2024,
    },
    setPricingConfig: getUpdaterFunction(set, "pricingConfig"),
  };
}
