export type PublicId<T extends string> = `${string}${T}`;

export function isPublicId<T extends string>(
  id: string | undefined | null,
  suffix: T,
): id is PublicId<T> {
  return id != null && id.endsWith(suffix);
}

export function getEncryptedIdFromPublicId<T extends string>(
  publicId: PublicId<T>,
  suffix: T,
): string {
  return publicId != null && publicId.slice(0, -suffix.length);
}
