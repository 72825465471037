import { CURRENT_TEAM_ID_KEY } from "@/components/constants/backend";
import { editorContextStore } from "@/contexts/editor-context";
import { AppRoleType, UserAuthChangedEventHandler } from "@/core/common/types";
import { isUserCustomClaims, UserCustomClaims } from "@/core/common/types/custom-claims";
import type { PublicTeamId } from "@/core/common/types/team";
import { debugError } from "@/core/utils/print-utilts";
import {
  getUserTeamRolesFromPublicUserId,
  removeCurrentTeamId,
  setCurrentTeamId,
  updateCurrentTeamIdOnUserLogin,
  useCurrentTeamId,
} from "@/core/utils/team-utils";
import { usePostHog } from "posthog-js/react";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function useAuthUpdateEffect() {
  const backend = editorContextStore((state) => state.backend);
  const user = editorContextStore((state) => state.user);
  const userTeams = editorContextStore((state) => state.userTeams);
  const setUserTeams = editorContextStore((state) => state.setUserTeams);
  const setUser = editorContextStore((state) => state.setUser);
  const setUserQuotas = editorContextStore((state) => state.setUserQuotas);
  const setUserOnboardData = editorContextStore((state) => state.setUserOnboardData);
  const setTeamQuotas = editorContextStore((state) => state.setTeamQuotas);
  const publicUserId = editorContextStore((state) => state.publicUserId);
  const currentTeamId = useCurrentTeamId();
  const navigate = useNavigate();
  const posthog = usePostHog();

  useEffect(() => {
    const handleFocus = async () => {
      await user?.getIdTokenResult();
    };

    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return backend?.onAuthStateChanged(async (user) => {
      try {
        const { setPublicUserId } = editorContextStore.getState();

        setUser(user);
        if (!user) {
          setUserQuotas(null);
          setTeamQuotas(null);
          setUserOnboardData(null);
          setPublicUserId(null);
          setUserTeams({});
          removeCurrentTeamId();
          localStorage.removeItem(CURRENT_TEAM_ID_KEY);
        } else {
          // Get token directly from the user object
          const token = await user.getIdTokenResult(true);

          const customClaimsFromToken = token?.claims;

          const customClaims: UserCustomClaims | null = isUserCustomClaims(customClaimsFromToken)
            ? customClaimsFromToken
            : await backend.refreshCustomUserClaims();

          if (!customClaims) {
            // TODO: Display some error message??
            throw new Error("Missing custom claims");
          }

          const userTeamRoles =
            Object.keys(customClaims.teams ?? {}).length > 0
              ? (customClaims.teams ?? {})
              : getUserTeamRolesFromPublicUserId({
                  publicUserId: customClaims?.publicUserId,
                });

          const initialTeamId = updateCurrentTeamIdOnUserLogin({
            userTeamRoles,
          });

          const publicUserId = customClaims?.publicUserId;
          setPublicUserId(publicUserId);

          backend.onUserLogin({
            uid: user.uid,
          });
          if (!posthog?._isIdentified()) {
            posthog?.identify(user.uid, {
              email: user.email,
              name: user.displayName,
              uid: user.uid,
              publicUserId,
              publicTeamIds: Object.keys(userTeamRoles),
            });
          }

          if (initialTeamId) {
            posthog?.group("publicTeamId", initialTeamId);
          }
        }
        editorContextStore
          .getState()
          .editor?.emit<UserAuthChangedEventHandler>("user:auth-changed", user);
      } catch (error) {
        debugError("Error getting hanlding auth update: ", error);
      }
    });
  }, [backend, setUser, setUserQuotas, setUserOnboardData, setUserTeams, setTeamQuotas, posthog]);

  useEffect(() => {
    return backend?.onUserQuotasUpdate({
      userId: user?.uid,
      onUpdate: setUserQuotas,
    });
  }, [backend, user, setUserQuotas]);

  useEffect(() => {
    if (user) {
      backend?.getUserOnboardData(user?.uid).then((userOnboardData) => {
        setUserOnboardData(userOnboardData);
      });
    }
  }, [backend, user, setUserOnboardData]);

  // If a currently logged in user is removed from a team, update their team to their default team
  useEffect(() => {
    if (
      currentTeamId &&
      userTeams &&
      Object.keys(userTeams).length > 0 &&
      !Object.keys(userTeams).includes(currentTeamId)
    ) {
      const teamWhereUserIsOwner = Object.keys(userTeams).find(
        (teamId) => userTeams?.[teamId]?.roles?.[publicUserId] === AppRoleType.Owner,
      );

      if (teamWhereUserIsOwner) {
        setCurrentTeamId(teamWhereUserIsOwner as PublicTeamId);
      }
      navigate("/");
    }
  }, [userTeams, currentTeamId, navigate, publicUserId]);
}
