import { EditorActiveObject } from "@/core/common/interfaces";
import { ImageFormat } from "@/core/common/types/image";
import { getImageFormatFromImageArray } from "@/core/utils/image-utils";
import { debugLog } from "@/core/utils/print-utilts";
import mime from "mime";
import { displayUiMessage } from "./display-message";

export function getFileExtensionFromDataUri(dataUri: string): string | null {
  // Extract the MIME type from the Data URI
  const mimeTypeMatch = dataUri.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  if (!mimeTypeMatch) {
    return null;
  }

  // Get the MIME type
  const mimeType = mimeTypeMatch[1];

  // Use the mime library to get the file extension
  const extension = mime.getExtension(mimeType);

  return extension;
}

export const toBase64 = (file: File | Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function downloadDataUrl(url: string, filename: string) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
  link.remove();
}

// Helper function to determine the filename
async function determineFilename(
  response: Response,
  blob: Blob,
  url: string,
  filename?: string,
): Promise<string> {
  // 1. Use the provided filename if available
  if (filename) {
    return filename;
  }

  // 2. Extract filename from Content-Disposition header
  const headerFilename = extractFilenameFromHeader(response);
  if (headerFilename) {
    return headerFilename;
  }

  // 3. Extract filename from URL
  const urlFilename = extractFilenameFromUrl(url);
  if (urlFilename) {
    return urlFilename;
  }

  // 4. Use a default filename if all else fails
  return "download";
}

// Helper function to extract filename from Content-Disposition header
function extractFilenameFromHeader(response: Response): string | null {
  const disposition = response.headers.get("Content-Disposition");
  if (disposition && disposition.includes("filename")) {
    const matches = disposition.match(
      /filename\*=UTF-8''([^;]+)|filename="([^"]+)"|filename=([^;]+)/,
    );
    if (matches) {
      return decodeURIComponent(matches[1] || matches[2] || matches[3]);
    }
  }
  return null;
}

// Helper function to extract filename from URL
function extractFilenameFromUrl(url: string): string | null {
  const urlObj = new URL(url);
  const pathname = decodeURIComponent(urlObj.pathname);
  const segments = pathname.split("/").filter(Boolean);
  return segments.pop() || null;
}

// Helper function to determine the file extension
function determineExtension(blob: Blob): string | null {
  const contentType = blob.type;

  // Use mime package to get the extension from a valid MIME type
  const extension = mime.getExtension(contentType);
  return extension || extractExtensionFromPath(contentType) || "bin";
}

// Helper function to extract extension from a path
function extractExtensionFromPath(path: string): string | null {
  const filename = path.split("/").pop();
  if (filename && filename.includes(".")) {
    return filename.split(".").pop() || null;
  }
  return null;
}

// Helper function to trigger the download
function triggerDownload(blob: Blob, filename: string): void {
  // Ensure the filename has an extension
  if (!filename.includes(".")) {
    const extension = determineExtension(blob);
    filename += `.${extension}`;
  }

  const objectUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(objectUrl);
}

export async function downloadUrl(url: string, filename?: string): Promise<void> {
  try {
    const response = await fetch(url, { mode: "cors" });
    if (!response.ok) {
      throw new Error(`Failed to fetch resource: ${response.status} ${response.statusText}`);
    }

    const blob = await response.blob();

    // Determine the final filename
    const finalFilename = await determineFilename(response, blob, url, filename);

    // Create an object URL and trigger the download
    triggerDownload(blob, finalFilename);
  } catch (error) {
    console.error("Download failed:", error);
    alert("Failed to download the file. Please try again later.");
  }
}

export async function downloadVideoUrl(url: string, filename: string): Promise<void> {
  return downloadUrl(url, filename);
}

export function downloadDataUrlNewTab(url: string, filename: string) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.target = "_blank"; // Open in a new window or tab
  document.body.appendChild(link); // Append to the body to ensure visibility
  link.click();
  link.remove();
}

export function getStaticImageFileStem(image?: EditorActiveObject) {
  try {
    return image ? `image_${image.id?.substring(0, 3)}` : "";
  } catch (error) {
    debugLog("Error getting file stem from image: ", error);
    return "";
  }
}

function isBlobWebp(blob: Blob): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const arr = new Uint8Array(reader.result as ArrayBuffer);
      // Check for WebP signature 'RIFF' followed by 'WEBP'
      const isWebp =
        arr[0] === 0x52 &&
        arr[1] === 0x49 &&
        arr[2] === 0x46 &&
        arr[3] === 0x46 &&
        arr[8] === 0x57 &&
        arr[9] === 0x45 &&
        arr[10] === 0x42 &&
        arr[11] === 0x50;
      resolve(isWebp);
    };
    reader.onerror = () => reject(new Error("Error reading blob"));
    reader.readAsArrayBuffer(blob.slice(0, 12)); // Read the first 12 bytes of the blob
  });
}

export function getImageFormatFromBlob(blob: Blob): Promise<ImageFormat | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const arr = new Uint8Array(reader.result as ArrayBuffer);
      resolve(getImageFormatFromImageArray(arr));
    };
    reader.onerror = () => reject(new Error("Error reading blob"));
    reader.readAsArrayBuffer(blob.slice(0, 12)); // Read the first 12 bytes of the blob
  });
}

export type DownloadImageBlobArgs = {
  blob: Blob;
  contentType?: string | null;
  filename: string;
  strictExtension?: string;
};

export async function downloadImageBlob({
  blob,
  contentType,
  filename,
  strictExtension,
}: DownloadImageBlobArgs) {
  if (contentType === "image/jpeg") {
    contentType = (await isBlobWebp(blob)) ? "image/webp" : contentType;
  }

  let extension: string | null = null;

  if (strictExtension) {
    extension = strictExtension.trim();
  } else {
    extension = contentType ? mime.getExtension(contentType) : null;
  }

  if (extension) {
    filename = `${filename}.${extension}`;
  }

  const dataUrl = URL.createObjectURL(blob);
  downloadDataUrl(dataUrl, filename);
  URL.revokeObjectURL(dataUrl);
}

export function downloadImageDataUrl(
  url: string | undefined,
  filename: string,
  strictExtension?: string,
): Promise<void> {
  if (!url) {
    return Promise.resolve();
  }

  return fetch(url)
    .then(async (response) => {
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      // Prefer blob.type over headers for more reliable MIME type detection
      const blob = await response.blob();
      const contentType = blob.type || response.headers.get("Content-Type");

      debugLog(`Data url content type: ${contentType}`);

      return {
        contentType,
        blob,
      };
    })
    .then(async ({ blob, contentType }) => {
      debugLog(`Image content type: ${contentType}`);

      await downloadImageBlob({
        blob,
        contentType,
        filename,
        strictExtension,
      });
    })
    .catch((error) => {
      console.error("Error downloading image:", error);
      // Handle the error as per your application's needs
      displayUiMessage(
        `Cannot download image ${filename} from url ${url.slice(0, Math.min(url.length, 10))}`,
        "error",
      );
    });
}

export function getObjectUrlFromJsonString(data: string) {
  const blob = new Blob([data], { type: "text/json" });
  return window.URL.createObjectURL(blob);
}

export function downloadJson(data: string, filename: string) {
  return downloadDataUrl(getObjectUrlFromJsonString(data), filename);
}

export function isImagePngDataUrl(url: string) {
  return url?.startsWith("data:image/png;base64");
}
