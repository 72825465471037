import { editorContextStore } from "@/contexts/editor-context";
import { debugError } from "@/core/utils/print-utilts";
import { useMemo } from "react";

export function useCanUserUploadAsset() {
  const userQuotas = editorContextStore((state) => state.userQuotas);

  return useMemo(() => {
    try {
      const { totalAssetSizeBytes = 0, maxAssetSizeBytes = 0 } = userQuotas;
      return totalAssetSizeBytes < maxAssetSizeBytes;
    } catch (error) {
      debugError("Error checking if user can upload asset: ", error);
      return false;
    }
  }, [userQuotas]);
}
