export function appendPromptInstructions({
  textToAdd,
  prompt,
}: {
  textToAdd: string;
  prompt: string;
}) {
  return `
      Given a prompt and some text, append the text to the prompt in such a way that any existing
      context covered by the text is replaced in the prompt directly—or, if there's no conflict,
      the text is appended. Follow these specific rules:

      1) BACKGROUND REPLACEMENT
      If the text describes a new background or scene, remove all references to the existing
      scene, setting, or background in the prompt. Then append the new background.

      2) CLOTHING / ACCESSORIES
      If the text describes a new article of clothing or accessory:
      If a human can only wear one of a certain item at a time (e.g., one shirt, one pair of pants,
      one jacket, one pair of shoes, one hat), REPLACE the old item with the new one.
      If a human can reasonably wear multiples (e.g., two earrings, two watches, two purses),
      then ADD the new item in addition to the old one.

      3) HUMAN DESCRIPTIONS
      If the text describes a human model, such as "young woman [trigger1] with sleek pulled back hair":
      If there is ONLY ONE human in the prompt, REPLACE that human with the new description.
      If there are MULTIPLE humans in the prompt, ADD the new human as part of a group.

      4) TRIGGER HANDLING
      If the prompt or the text contains a [trigger] token, do not alter that token
      itself. For example, if the new text says "light blue [trigger0] pleated skirt," maintain
      "[trigger0]" as-is. No changes to spelling, casing, or bracket notation are allowed.

      5) TEXT TO BE ADDED
      Do not alter the text to be added. For example, if the new text says "background is a long,
      arched hallway with a stone ceiling and floor. Light streams in through tall, narrow windows
      along one side, casting a warm glow on the pale stone walls. The hallway extends into the
      distance, creating a sense of perspective and grandeur, motion photography, old money 35mm
      lens, professional fashion photography", ensure that it is not altered and returned as part of the output. No changes to
      spelling, casing, or bracket notation are allowed.

      6) FINAL OUTPUT
      Return the updated, combined prompt as a single coherent description in JSON format. Maintain details
      from the original prompt that do not conflict with or get replaced by the new text. Do not return any python code.
      Please return only the output in JSON format without any extra conversational text.

      Return a JSON object with the following structure:
      \`\`\`json
      {
          "modifiedPrompt": string,
          "isValid": boolean
      }
      \`\`\`

      - **isValid**: true if the caption meets all rules, false otherwise.
      - **modifiedPrompt**: the new prompt if the original was invalid, otherwise the same as the input.

      -------------------------------------------------------------------------------------------
      EXAMPLES
      -------------------------------------------------------------------------------------------
      Below are various examples that illustrate how to apply the above rules:

      Example #1: Adding an accessory
      ----------------------------------------------------------------------------
      Prompt:
      A photo of a woman white tight bun hair pulled back wearing a jacket [trigger0]
      with a fluffy collar posing on beige fabric against a beige vogue photoshoot in overexposed
      light and shadows, motion photography, old money 35mm lens, professional fashion photography

      Text to be added:
      chic [trigger1] handbag made of green leather with a silver chain strap

      Explanation:
      The new item is an accessory (a handbag), so we append it to the existing prompt without
      replacing anything else.

      Output:
      A photo of a woman white tight bun hair pulled back a jacket [trigger0] with a fluffy collar
      and a chic [trigger1] handbag made of green leather with a silver chain strap posing on beige
      fabric against a beige vogue photoshoot in overexposed light and shadows, motion photography,
      old money 35mm lens, professional fashion photography

      Example #2: Replacing the same clothing
      ----------------------------------------------------------------------------
      Prompt:
      Photo of a woman with a high pony tail, full lips, angular sharp features, focused determined
      expression on her face, cat like eyes and black hair wearing light blue [trigger0] pleated skirt
      with a zipper on the back at an outdoor tennis court in the hot bright sun, wearing a tennis hat,
      overexposed light, warm light, bright, midday sun

      Text to be added:
      neon green [trigger1] suit with a blazer and matching pants

      Explanation:
      A woman typically wears one "bottom" or one "main outfit" piece at a time. We replace the
      pleated skirt with the new suit.

      Output:
      {
        "modifiedPrompt": "Photo of a woman with a high pony tail, full lips, angular sharp features, focused determined expression on her face, cat like eyes and black hair wearing neon green [trigger1] suit with a blazer and matching pants at an outdoor tennis court in the hot bright sun, wearing a tennis hat, overexposed light, warm light, bright, midday sun",
        "isValid": true
      }


      Example #3: Replacing the same clothing
      ----------------------------------------------------------------------------
      Prompt:
      photo of an African American woman wearing a [trigger0] women's L.L.Bean Activewear ski jacket,
      in a sporty style, suitable for winter, new condition, in a burgundy and dusty rose color
      combination, with a quilted interior, made from a water-resistant shell fabric, with a hooded
      neckline, long sleeves, and two pockets, featuring a color-blocked design, a zipper front closure,
      and a relaxed fit, wearing snowboarding goggles and standing on a snowboard on the side of a mountain.

      Text to be added:
      white puffy down cropped [trigger1] woman's winter coat

      Explanation:
      The new coat replaces the old ski jacket.

      Output:
      {
        "modifiedPrompt": "photo of an African American woman wearing a white puffy down cropped [trigger1] woman's winter coat, wearing snowboarding goggles and standing on a snowboard on the side of a mountain.",
        "isValid": true
      }

      Example #4: Adding a perfume bottle
      ----------------------------------------------------------------------------
      Prompt:
      A photo of a woman wearing a classic black cocktail dress, standing in front of a shimmering
      city skyline at night, facing the camera with a poised expression.

      Text to be added:
      a small glass perfume bottle with a crystal stopper, held delicately in her right hand

      Explanation:
      A perfume bottle is a new accessory that can be added without conflicting with any existing item.

      Output:
      {
        "modifiedPrompt": "A photo of a woman wearing a classic black cocktail dress, standing in front of a shimmering city skyline at night, facing the camera with a poised expression, holding a small glass perfume bottle with a crystal stopper in her right hand.",
        "isValid": true
      }

      Example #5: Replacing a human
      ----------------------------------------------------------------------------
      Prompt:
      A photo of a middle-aged man with short blond hair wearing a casual gray t-shirt and blue jeans,
      leaning against a brick wall in an urban setting.

      Text to be added:
      A teenage boy [trigger1] with curly black hair wearing a red hoodie

      Explanation:
      There's only one human in the original prompt. We replace his description with the new one.

      Output:
      {
        "modifiedPrompt": "A photo of a teenage boy [trigger1] with curly black hair wearing a red hoodie, leaning against a brick wall in an urban setting.",
        "isValid": true
      }

      Example #6: Adding another human
      ----------------------------------------------------------------------------
      Prompt:
      A photo of a middle-aged man with short blond hair wearing a casual gray t-shirt and blue jeans
      next to a woman with long dark hair wearing a polka dot dress, leaning against a brick wall in
      an urban setting.

      Text to be added:
      A teenage boy [trigger2] with curly black hair wearing a red hoodie

      Explanation:
      Since there are already two distinct humans (the man and the woman), we add a third.

      Output:
      {
        "modifiedPrompt": "A photo of a middle-aged man with short blond hair wearing a casual gray t-shirt and blue jeans next to a woman with long dark hair wearing a polka dot dress, and a teenage boy [trigger2] with curly black hair wearing a red hoodie, leaning against a brick wall in an urban setting.",
        "isValid": true
      }

      Example #7: Adding a different article of clothing
      ----------------------------------------------------------------------------
      Prompt:
      A photo of a young woman wearing a black t-shirt [trigger0], standing on a city sidewalk.

      Text to be added:
      a denim jacket [trigger1] with a patch on the left arm

      Explanation:
      A t-shirt and a jacket can be worn together, so we append it.

      Output:
      {
        "modifiedPrompt": "A photo of a young woman wearing a black t-shirt [trigger0] and a denim jacket [trigger1] with a patch on the left arm, standing on a city sidewalk.",
        "isValid": true
      }

      Example #8: Replacing a background
      ----------------------------------------------------------------------------
      Prompt:
      photo of a a young woman [trigger0] with her hair pulled back in a bun, wearing silver hoop
      earrings wearing long brown and black coat with a tiger print, a double breasted closure, and
      a matching black belt posing on beige fabric against a beige vogue photoshoot in overexposed
      light and shadows, motion photography, old money 35mm lens, professional fashion photography

      Text to be added:
      The background is a long, arched hallway with a stone ceiling and floor. Light streams in
      through tall, narrow windows along one side, casting a warm glow on the pale stone walls. The
      hallway extends into the distance, creating a sense of perspective and grandeur.

      Explanation:
      The new text replaces the old background. We remove references to “beige fabric against a beige
      vogue photoshoot” and append the new hallway description.

      Output:
      {
        "modifiedPrompt": "photo of a a young woman [trigger0] with her hair pulled back in a bun, wearing silver hoop earrings wearing long brown and black coat with a tiger print, a double breasted closure, and a matching black belt. The background is a long, arched hallway with a stone ceiling and floor. Light streams in through tall, narrow windows along one side, casting a warm glow on the pale stone walls. The hallway extends into the distance, creating a sense of perspective and grandeur, motion photography, old money 35mm lens, professional fashion photography",
        "isValid": true
      }


      -------------------------------------------------------------------------------------------
      USAGE
      -------------------------------------------------------------------------------------------
      When you receive:
       - The original prompt
       - The text to be added
      You must:
        (1) Identify if the text is a background, clothing, accessory, or new human.
        (2) Replace conflicting elements or append if they can coexist.
        (3) Maintain all relevant details and triggers [trigger0], [trigger1], etc.
        (4) Return the final updated prompt as a single coherent string.
      Now do the same for the following input:
      -------------------------------------------------------------------------------------------
      INPUTS
      -------------------------------------------------------------------------------------------
      Prompt:\n ${prompt}

      Text to be added:\n ${textToAdd}

      Output:
    `.trim();
}

export function removePromptInstructions({
  textToRemove,
  prompt,
}: {
  textToRemove: string;
  prompt: string;
}) {
  return `
# These instructions define how to remove specified text from an existing prompt context.
# If the text to remove matches something in the prompt, remove it in a way that preserves
# overall coherence. If it doesn't match, do nothing.

Given a prompt and some text, remove the text from the prompt if there is a meaningful match
(or equivalence). Otherwise, do nothing. Follow these specific rules:

1) BACKGROUND REMOVAL
   # If the text describes a background that matches the prompt's background, remove that
   # background description. Replace it with "in front of a plain background." If the prompt's
   # background does NOT match the text, do nothing.

2) HUMAN REMOVAL
   # If the text describes a human that is present in the prompt:
   #   - If there is exactly one human in the prompt, remove the details of that human and replace
   #     them with "person" or "a person," keeping the rest of the scene if relevant.
   #   - If there are multiple humans in the prompt, remove only the matching human. The other
   #     humans remain unchanged.
   # If the text describes a human that does NOT exist in the prompt, do nothing.

3) CLOTHING REMOVAL
   # If the text describes a piece of clothing (e.g., a jacket, skirt, etc.) that matches something
   # the existing human(s) is wearing, remove that clothing from the prompt. If the text describes
   # clothing that is not found in the prompt, do nothing.

4) ACCESSORY REMOVAL
   # If the text describes an accessory (e.g., handbag, watch, goggles) that matches something
   # already in the prompt, remove that accessory. If it's not found, do nothing.

5) FINAL OUTPUT
   # Return the updated prompt as a single coherent description, ensuring that any removed items
   # are replaced appropriately (e.g., "in front of a plain background," or "person" in place of
   # a removed human).
   # If the text does not match anything in the prompt, simply return the original prompt
   # unchanged. Do not return any python code.

# -------------------------------------------------------------------------------------------
# INPUTS
# -------------------------------------------------------------------------------------------
# - The original prompt: ${prompt}
# - The text to be removed: ${textToRemove}
# -------------------------------------------------------------------------------------------

# -------------------------------------------------------------------------------------------
# EXAMPLES
# -------------------------------------------------------------------------------------------

# Example #1: Remove a background that exists in the prompt
# ----------------------------------------------------------------------------
# Prompt:
# photo of a a young woman [trigger0] with her hair pulled back in a bun, wearing silver hoop
# earrings wearing long brown and black coat with a tiger print, a double breasted closure, and a
# matching black belt. The background is a long, arched hallway with a stone ceiling and floor.
# Light streams in through tall, narrow windows along one side, casting a warm glow on the pale
# stone walls. The hallway extends into the distance, creating a sense of perspective and grandeur,
# motion photography, old money 35mm lens, professional fashion photography
#
# Text to be removed:
# The background is a long, arched hallway with a stone ceiling and floor. Light streams in
# through tall, narrow windows along one side, casting a warm glow on the pale stone walls.
# The hallway extends into the distance, creating a sense of perspective and grandeur.
#
# Explanation:
# The prompt’s background matches exactly. We remove it and replace with "in front of a plain
# background."
#
# Output:
# photo of a a young woman [trigger0] with her hair pulled back in a bun, wearing silver hoop
# earrings wearing long brown and black coat with a tiger print, a double breasted closure, and a
# matching black belt, in front of a plain background, motion photography, old money 35mm lens,
# professional fashion photography

# Example #2: Remove a background that doesn’t exist in the prompt
# ----------------------------------------------------------------------------
# Prompt:
# A portrait of a woman in a pink cocktail dress standing near a large window overlooking the city.
#
# Text to be removed:
# The background is a sandy beach with palm trees along the shore.
#
# Explanation:
# The prompt doesn't mention a beach or palm trees, so there's no match to remove.
#
# Output (unchanged):
# A portrait of a woman in a pink cocktail dress standing near a large window overlooking the city.

# Example #3: Remove a human that exists in the prompt when there are multiple humans
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a middle-aged man with short blond hair wearing a casual gray t-shirt and blue jeans
# next to a woman with long dark hair wearing a polka dot dress, leaning against a brick wall in
# an urban setting.
#
# Text to be removed:
# a woman with long dark hair wearing a polka dot dress
#
# Explanation:
# The prompt has multiple humans. We remove only the matching woman. The man remains. Replace
# that woman's spot with "a person" or simply remove it, depending on context.
#
# Output:
# A photo of a middle-aged man with short blond hair wearing a casual gray t-shirt and blue jeans,
# leaning against a brick wall in an urban setting.

# Example #4: Remove a human that exists in the prompt when there is one human in the prompt
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a single mother with curly black hair wearing a yellow sweater, smiling at the camera.
#
# Text to be removed:
# a single mother with curly black hair wearing a yellow sweater
#
# Explanation:
# There's only one person. We remove that description entirely and replace it with "a person."
#
# Output:
# A photo of a person, smiling at the camera.

# Example #5: Remove a human that doesn’t exist in the prompt
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a single mother with curly black hair wearing a yellow sweater, smiling at the camera.
#
# Text to be removed:
# a tall blond man wearing a blue suit
#
# Explanation:
# No match for that description in the prompt. Prompt remains unchanged.
#
# Output (unchanged):
# A photo of a single mother with curly black hair wearing a yellow sweater, smiling at the camera.

# Example #6: Remove clothing that exists in the prompt
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a young woman wearing a black t-shirt [trigger0] and a denim jacket [trigger1]
# with a patch on the left arm, standing on a city sidewalk.
#
# Text to be removed:
# a denim jacket [trigger1] with a patch on the left arm
#
# Explanation:
# That exact piece of clothing exists. Remove it from the prompt, leaving the rest.
#
# Output:
# A photo of a young woman wearing a black t-shirt [trigger0], standing on a city sidewalk.

# Example #7: Remove clothing that doesn’t exist in the prompt
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a young woman wearing a black t-shirt [trigger0] and a denim jacket [trigger1]
# with a patch on the left arm, standing on a city sidewalk.
#
# Text to be removed:
# a neon green skirt
#
# Explanation:
# The prompt doesn't mention a neon green skirt. No match, so do nothing.
#
# Output (unchanged):
# A photo of a young woman wearing a black t-shirt [trigger0] and a denim jacket [trigger1]
# with a patch on the left arm, standing on a city sidewalk.

# Example #8: Remove an accessory that exists in the prompt
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a woman wearing a classic black cocktail dress, standing in front of a shimmering
# city skyline at night, facing the camera with a poised expression, holding a small glass perfume
# bottle with a crystal stopper in her right hand.
#
# Text to be removed:
# a small glass perfume bottle with a crystal stopper
#
# Explanation:
# That accessory exists. Remove it from the prompt.
#
# Output:
# A photo of a woman wearing a classic black cocktail dress, standing in front of a shimmering
# city skyline at night, facing the camera with a poised expression.

# Example #9: Remove an accessory that doesn’t exist in the prompt
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a woman wearing a classic black cocktail dress, standing in front of a shimmering
# city skyline at night, facing the camera with a poised expression.
#
# Text to be removed:
# a bright red clutch purse
#
# Explanation:
# The prompt has no mention of a bright red clutch purse. No match, so do nothing.
#
# Output (unchanged):
# A photo of a woman wearing a classic black cocktail dress, standing in front of a shimmering
# city skyline at night, facing the camera with a poised expression.

# -------------------------------------------------------------------------------------------
# USAGE
# -------------------------------------------------------------------------------------------
# Given the following inputs:
#  - The original prompt
#  - The text to remove
# You must:
#   (1) Identify if the text to remove matches a background, a human, clothing, or accessory
#       that exists in the prompt.
#   (2) If it matches, remove it appropriately (replace background with "in front of a plain background,"
#       replace single human with "a person," remove only that one human if multiple, etc.).
#   (3) If it does not match, do nothing.
#   (4) Return the final updated prompt as a single coherent string.
`;
}
