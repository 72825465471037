import GoogleIcon from "@/components/icons/google-icon";
import { editorContextStore } from "@/contexts/editor-context";
import { AppUser } from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import { debugLog } from "@/core/utils/print-utilts";

const buttonClassNameBase =
  "w-full rounded-md border-solid border border-zinc-700 transition-colors my-4 px-2 py-2 flex flex-row justify-center items-center shadow-md box-border";

const buttonClassName = classNames(
  buttonClassNameBase,
  "text-zinc-300 hover:text-lime-500 cursor-pointer hover:border-lime-500",
);

const buttonClassNameLoading = classNames(buttonClassNameBase, "text-zinc-500 cursor-wait");

const buttonClassNameError = classNames(buttonClassNameBase, "text-zinc-500 cursor-not-allowed");

export function LoginWithGoogle({
  disabled = false,
  isSigningIn,
  setIsSigningIn,
  onSignIn,
}: {
  disabled?: boolean;
  isSigningIn: boolean;
  setIsSigningIn: (value: boolean) => void;
  onSignIn: (user: AppUser | null) => void;
}) {
  const isMobile = editorContextStore((state) => state.isMobile);
  return (
    <button
      type="button"
      className={classNames(
        disabled ? buttonClassNameError : isSigningIn ? buttonClassNameLoading : buttonClassName,
      )}
      onClick={() => {
        if (disabled) {
          return;
        }
        if (isSigningIn) {
          return;
        }
        const { backend } = editorContextStore.getState();
        if (backend) {
          debugLog("Login with fullscreen");
          setIsSigningIn(true);
          backend.continueWithGoogle({ isMobile }).then(onSignIn).catch(console.error);
        }
      }}
    >
      <GoogleIcon />
      <span className="mr-2">Continue with Google</span>
    </button>
  );
}
