import { PublicTeamId } from "./public-team-id";
import { isPublicUserId, PublicUserId } from "./public-user-id";
import { AppRoleType } from "./user-roles";

export type UserCustomClaimsTeamRoles = Record<PublicTeamId, AppRoleType>;

export function getDefaultPublicTeamIdFromUserCustomClaimsTeamRoles(
  userTeamRoles: UserCustomClaimsTeamRoles,
) {
  return Object.entries(userTeamRoles).find(([_, role]) => role === AppRoleType.Owner)?.[0] as
    | PublicTeamId
    | undefined;
}

export interface UserCustomClaims {
  publicUserId: PublicUserId;
  teams?: UserCustomClaimsTeamRoles;
}

export function isUserCustomClaims(claims: any): claims is UserCustomClaims {
  return claims && claims.publicUserId && isPublicUserId(claims.publicUserId);
}
