import { editorContextStore } from "@/contexts/editor-context";
import {
  SetEditorStateFunction,
  getUpdaterFunction,
  getUpdaterFunctionWithCleanup,
} from "@/contexts/editor-context-utils";
import { noop } from "lodash";
import { RenderPipelineArgs } from "./render-args";
import { SceneJSON } from "./scene-json";
import { StateUpdater } from "./utils";

export enum RealTimeRenderMode {
  Disabled = "disabled",
  Active = "active",
}

export enum RealTimeRenderStatus {
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
  WAIT = "wait",
  SEND_FRAME = "send_frame",
  TIMEOUT = "timeout",
}

export enum RealTimeRenderResultType {
  None = "none",
  RawRender = "raw-render",
  RefColorCorrect = "ref-color-correct",
  OverlayColorCorrect = "overlay-color-correct",
}

export interface IRealTimeRenderController {
  readonly renderInputImage: string;
  readonly renderInputMaskImage: string;
  readonly renderInputSceneJson?: SceneJSON;
  destroy: () => void;
  render: () => void;
}

type ChannelRGBA = "R" | "G" | "B" | "A";

export type RealTimeRenderPipelineArgs = RenderPipelineArgs & {
  scheduler_timestep_indices?: [number, number];
  update_controlnet_args?: boolean;
  update_init_image_args?: boolean;
  update_ip_adapter_or_prompt_args?: boolean;
  use_previous_init_image_latents?: boolean;
  composite_mask_channel_for_canny?: ChannelRGBA;
  composite_mask_channel_for_foreground?: ChannelRGBA;
};

export type RealTimeRenderPipelineArgsResult = {
  sceneJSON?: SceneJSON;
  renderPipelineArgs?: RealTimeRenderPipelineArgs;
};

export type RealTimeUserId = `${string}-${string}-${string}-${string}-${string}`;

export enum RealTimeServerTier {
  Free = "free",
  Paid = "paid",
}

export type RealTimeServerId = string;

// function createRealTimeServerIds(length: number): RealTimeServerId[] {
//     const result: RealTimeServerId[] = [];
//     for (let i = 1; i <= length; i++) {
//         result.push(`${i}`);
//     }
//     return result;
// }
// export const realtimeServerIds = createRealTimeServerIds(7);

export type RealTimeServerConfig = {
  id: RealTimeServerId;
  tier: RealTimeServerTier;
};

export function isRealTimeServerConfig(obj: any): obj is RealTimeServerConfig {
  return (
    obj &&
    typeof obj.id === "string" && // Adjust this check based on the actual type of RealTimeServerId
    ["free", "paid"].includes(obj.tier)
  ); // Adjust this check if RealTimeServerTier is different
}

export interface RealTimeRenderEditorState {
  realtimeServerId?: RealTimeServerId;
  setRealtimeServerId: (value: StateUpdater<RealTimeServerId | undefined>) => void;
  realtimeUserId?: RealTimeUserId;
  setRealtimeUserId: (value: StateUpdater<RealTimeUserId | undefined>) => void;
  realtimeRenderMode: RealTimeRenderMode;
  setRealtimeRenderMode: (value: StateUpdater<RealTimeRenderMode>) => void;
  realtimeRenderStatus: RealTimeRenderStatus;
  setRealtimeRenderStatus: (value: StateUpdater<RealTimeRenderStatus>) => void;
  realtimeRenderController: IRealTimeRenderController | undefined;
  setRealtimeRenderController: (value: StateUpdater<IRealTimeRenderController | undefined>) => void;
  realtimeRenderResultType: RealTimeRenderResultType;
  setRealtimeRenderResultType: (value: StateUpdater<RealTimeRenderResultType>) => void;
  realtimeRenderProgress: number;
  setRealtimeRenderProgress: (value: StateUpdater<number>) => void;
  realtimeColorCorrectImageUrl?: string;
  setRealtimeColorCorrectImageUrl: (value: StateUpdater<string | undefined>) => void;
  realtimeServerTier?: RealTimeServerTier;
  setRealtimeServerTier: (value: StateUpdater<RealTimeServerTier | undefined>) => void;
  realtimeRenderIsPointerOverResultRef: { current: boolean };
  realtimeRenderIgnoreRenderOnceRef: { current: boolean };
}

export function getDummyRealTimeRenderEditorState(): RealTimeRenderEditorState {
  return {
    realtimeUserId: undefined,
    setRealtimeUserId: noop,
    realtimeServerId: undefined,
    setRealtimeServerId: noop,
    realtimeRenderMode: RealTimeRenderMode.Disabled,
    setRealtimeRenderMode: noop,
    realtimeRenderStatus: RealTimeRenderStatus.DISCONNECTED,
    setRealtimeRenderStatus: noop,
    realtimeRenderController: undefined,
    setRealtimeRenderController: noop,
    realtimeRenderResultType: RealTimeRenderResultType.None, // Default value for dummy state
    setRealtimeRenderResultType: noop, // noop for the setter in dummy state
    realtimeRenderProgress: 0.0,
    setRealtimeRenderProgress: noop,
    realtimeColorCorrectImageUrl: undefined,
    setRealtimeColorCorrectImageUrl: noop,
    realtimeServerTier: undefined,
    setRealtimeServerTier: noop,
    realtimeRenderIsPointerOverResultRef: { current: false },
    realtimeRenderIgnoreRenderOnceRef: { current: false },
  };
}

export function getDefaultRealTimeRenderEditorState(
  set: SetEditorStateFunction,
): RealTimeRenderEditorState {
  return {
    realtimeServerId: undefined,
    setRealtimeServerId: getUpdaterFunction(set, "realtimeServerId"),
    realtimeUserId: undefined,
    setRealtimeUserId: getUpdaterFunction(set, "realtimeUserId"),
    realtimeRenderMode: RealTimeRenderMode.Disabled,
    setRealtimeRenderMode: getUpdaterFunction(set, "realtimeRenderMode"),
    realtimeRenderStatus: RealTimeRenderStatus.DISCONNECTED,
    setRealtimeRenderStatus: getUpdaterFunction(set, "realtimeRenderStatus"),
    realtimeRenderController: undefined,
    setRealtimeRenderController: getUpdaterFunctionWithCleanup(
      set,
      "realtimeRenderController",
      (controller) => {
        controller?.destroy();
      },
    ),
    realtimeRenderResultType: RealTimeRenderResultType.None, // Default value
    setRealtimeRenderResultType: getUpdaterFunction(set, "realtimeRenderResultType"), // Updater function for the new property
    realtimeRenderProgress: 0.0,
    setRealtimeRenderProgress: getUpdaterFunction(set, "realtimeRenderProgress"),
    realtimeColorCorrectImageUrl: undefined,
    setRealtimeColorCorrectImageUrl: getUpdaterFunction(set, "realtimeColorCorrectImageUrl"),
    realtimeServerTier: undefined,
    setRealtimeServerTier: getUpdaterFunction(set, "realtimeServerTier"),
    realtimeRenderIsPointerOverResultRef: { current: false },
    realtimeRenderIgnoreRenderOnceRef: { current: false },
  };
}

export function resetRealtimeRenderEditorState() {
  const {
    setRealtimeServerId,
    setRealtimeUserId,
    setRealtimeServerTier,
    realtimeRenderIsPointerOverResultRef,
    realtimeRenderIgnoreRenderOnceRef,
    setRealtimeRenderMode,
    setRealtimeRenderStatus,
    setRealtimeRenderResultType,
    setRealtimeRenderProgress,
    setRealtimeColorCorrectImageUrl,
    setRealtimeRenderController,
  } = editorContextStore.getState();
  setRealtimeRenderMode(RealTimeRenderMode.Disabled);
  setRealtimeRenderStatus(RealTimeRenderStatus.DISCONNECTED);
  setRealtimeRenderResultType(RealTimeRenderResultType.None);
  setRealtimeRenderProgress(0);
  setRealtimeColorCorrectImageUrl(undefined);
  setRealtimeRenderController(undefined);
  setRealtimeServerId(undefined);
  setRealtimeUserId(undefined);
  setRealtimeServerTier(undefined);
  realtimeRenderIsPointerOverResultRef.current = false;
  realtimeRenderIgnoreRenderOnceRef.current = false;
}

export type RealTimeColorCorrectionMessage = {
  // "status": 'color_correct',
  //         'input_params': {
  //             'product_color_correction_image': get_data_url_from_image(product_color_correction_image, 'WEBP') if isinstance(product_color_correction_image, Image.Image) else None,
  //             'product_color_correction_mask_image': get_data_url_from_image(product_color_correction_mask_image, 'WEBP') if isinstance(product_color_correction_mask_image, Image.Image) else None,
  //         },
  //         'output_raw_image': get_data_url_from_image(output_raw_image, 'WEBP'),
  status: "color_correct";
  input_params: {
    prompt?: string;
    negative_prompt?: string;
    composite_image?: string;
    composite_mask_image?: string;
  };
  output_raw_image: string;
};

export function isRealTimeColorCorrectionMessage(
  message: any,
): message is RealTimeColorCorrectionMessage {
  return (
    message &&
    message.status === "color_correct" &&
    message.input_params &&
    typeof message.output_raw_image === "string"
  );
}
