import { CURRENT_TEAM_ID_KEY } from "@/components/constants/backend";
import { editorContextStore } from "@/contexts/editor-context";
import { getPublicTeamIdFromPublicUserId } from "@/core/common/types/public-team-id";
import { isPublicTeamId, PublicTeamId } from "@/core/common/types/team";
import { AppRoleType, canRoleRead, PublicUserId } from "../common/types";
import {
  getDefaultPublicTeamIdFromUserCustomClaimsTeamRoles,
  UserCustomClaimsTeamRoles,
} from "../common/types/custom-claims";
import { debugError } from "./print-utilts";

function getSessionStorageCurrentTeamIdKey() {
  return CURRENT_TEAM_ID_KEY;
}

function getCurrentTeamIdFromSessionStorage() {
  try {
    const savedTeamId = sessionStorage.getItem(getSessionStorageCurrentTeamIdKey());
    if (savedTeamId) {
      return savedTeamId as PublicTeamId;
    }
    return null;
  } catch (error) {
    debugError(error);
  }
}

export function getCurrentTeamId(): PublicTeamId | null {
  return editorContextStore.getState().currentTeamId;
}

export function useCurrentTeamId() {
  return editorContextStore((state) => state.currentTeamId);
}

export function setCurrentTeamId(teamId: PublicTeamId | null) {
  editorContextStore.getState().setCurrentTeamId(teamId);

  try {
    const sessionStorageKey = getSessionStorageCurrentTeamIdKey();

    if (teamId && isPublicTeamId(teamId)) {
      sessionStorage.setItem(sessionStorageKey, teamId);
    } else {
      sessionStorage.removeItem(sessionStorageKey);
    }
  } catch (error) {
    debugError(error);
  }
}

export function removeCurrentTeamId() {
  setCurrentTeamId(null);
}

function getCurrentTeamIdOnUserLogin({
  userTeamRoles,
}: {
  userTeamRoles: UserCustomClaimsTeamRoles;
}) {
  // first check if the team id is in the session storage and the user actually has access to it
  const savedTeamId = getCurrentTeamIdFromSessionStorage();
  if (savedTeamId && isPublicTeamId(savedTeamId)) {
    const userRole = userTeamRoles[savedTeamId];

    if (canRoleRead(userRole)) {
      return savedTeamId;
    }
  }

  return getDefaultPublicTeamIdFromUserCustomClaimsTeamRoles(userTeamRoles) ?? null;
}

export function updateCurrentTeamIdOnUserLogin({
  userTeamRoles,
}: {
  userTeamRoles: UserCustomClaimsTeamRoles;
}) {
  const currentTeamId = getCurrentTeamIdOnUserLogin({
    userTeamRoles,
  });

  setCurrentTeamId(currentTeamId);

  return currentTeamId;
}

export function getUserTeamRolesFromPublicUserId({
  publicUserId,
}: {
  publicUserId?: PublicUserId;
}): UserCustomClaimsTeamRoles {
  if (!publicUserId) {
    return {};
  }

  const publicTeamIdFromPublicUserId = getPublicTeamIdFromPublicUserId(publicUserId);

  if (!publicTeamIdFromPublicUserId) {
    return {};
  }

  return {
    [publicTeamIdFromPublicUserId]: AppRoleType.Owner,
  };
}
