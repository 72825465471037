import { editorContextStore } from "@/contexts/editor-context";
import { DisplayMessageType, UiDisplayMessageEventHandler } from "@/core/common/types";

export function displayUiMessage(
  message: string,
  type: DisplayMessageType = "info",
  title?: string,
  actionMessage?: string,
  onAction?: () => void | Promise<void>,
) {
  try {
    const { eventEmitter } = editorContextStore.getState();
    eventEmitter.emit<UiDisplayMessageEventHandler>(
      "ui:display-message",
      type,
      message,
      title,
      actionMessage,
      onAction,
    );
  } catch (error) {
    console.error(error);
  }
}
