// Firebase configuration and initialization
import { FirebaseOptions } from "firebase/app";

const stablePagesURLs = [
  "app.flair.ai",
  "ando-editor-prod.pages.dev",
  "ando-editor-staging.pages.dev",
] as const;

const host = window?.location?.hostname;
const isStablePage = stablePagesURLs?.some((su) => host === su);

export const FLAIR_AI_FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  //oauth proxy only to run for prod/staging stable urls
  authDomain:
    //auth domain should only be set on stable configurations - e.g app.flair.ai
    import.meta.env.VITE_FIREBASE_AUTH_DOMAIN ??
    `${import.meta.env.VITE_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID ?? "flair-ai-dev",
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET ?? "flair-ai-dev.appspot.com",
  messagingSenderId: import.meta.env.VITE_FIREBASE_PROJECT_NUMBER,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

export const API_ENDPOINTS = {
  REMOVE_BACKGROUND: import.meta.env.VITE_REMOVE_BACKGROUND_API_URL,
  UPSCALE: import.meta.env.VITE_UPSCALE_API_URL,
  PARSE_CLOTH: import.meta.env.VITE_TRYON_PARSE_CLOTH_API_URL,
  WARP_CLOTH: import.meta.env.VITE_TRYON_WARP_CLOTH_API_URL,
  RENDER_CLOTH: import.meta.env.VITE_TRYON_RENDER_CLOTH_API_URL,
  CAPTION: import.meta.env.VITE_CAPTION_API_URL,
};
