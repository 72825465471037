import { AppUserSubscriptionTierV2 } from "../types";

export enum AppSubscriptionPlanType {
  Free = "Free",
  Pro = "Pro",
  ProPlus = "Pro+",
  Scale = "Scale",
  ScaleTier2 = "ScaleTier2",
  ScaleTier3 = "ScaleTier3",
  ScaleTier4 = "ScaleTier4",
  ScaleTier5 = "ScaleTier5",
  Enterprise = "Enterprise",
}

export function getAppSubscriptionPlanType(planType: string): AppSubscriptionPlanType {
  const normalizedPlanType = planType.toLowerCase();

  const planTypeMap: Record<string, AppSubscriptionPlanType> = {
    free: AppSubscriptionPlanType.Free,
    pro: AppSubscriptionPlanType.Pro,
    proplus: AppSubscriptionPlanType.ProPlus,
    scale: AppSubscriptionPlanType.Scale,
    scaletier2: AppSubscriptionPlanType.ScaleTier2,
    scaletier3: AppSubscriptionPlanType.ScaleTier3,
    scaletier4: AppSubscriptionPlanType.ScaleTier4,
    scaletier5: AppSubscriptionPlanType.ScaleTier5,
    enterprise: AppSubscriptionPlanType.Enterprise,
  };

  const result = planTypeMap[normalizedPlanType];
  if (!result) {
    return null;
  }

  return result;
}

export enum AppSubscriptionPlanTypeToDisplay {
  Free = "Free",
  Pro = "Pro",
  ProPlus = "Pro+",
  Scale = "Scale",
  Enterprise = "Enterprise",
}

// TODO: update the price ids
export enum StripeSubscriptionPriceId {
  IndividualProPlan = "price_1MdzaWLI14x6JehmXZPh9zlE",
  IndividualProPlanAnnually = "price_1Q8bunLI14x6JehmKB6Azvqr",
  IndividualProPlusPlan = "price_1OmNjPLI14x6Jehm1iGkRijd",
  IndividualProPlusPlanAnnually = "price_1Q8btgLI14x6JehmRZwv8VUL",
  IndividualScalePlanMonthly = "price_1Q77NFLI14x6JehmYKUnT2rj",
  IndividualScalePlanAnnualy = "price_1Q8bsGLI14x6JehmPmMhiJP4",
  IndividualProPlanV2 = "price_1QeXrbLI14x6JehmBhPEI0lo",
  IndividualProPlanV2Annually = "price_1QeWxcLI14x6JehmpHcqe9cb",
  IndividualProPlusPlanV2 = "price_1QeXs3LI14x6JehmQd5mxXsN",
  IndividualProPlusPlanV2Annually = "price_1QeWy8LI14x6JehmKLYvdexl",
  IndividualScaleTier1PlanMonthly = "price_1QeWpgLI14x6Jehmmhyl1gJO",
  IndividualScaleTier1PlanAnnualy = "price_1QeWqtLI14x6Jehm8dPmsYOI",
  IndividualScaleTier2PlanMonthly = "price_1QePObLI14x6JehmodvLjOXf",
  IndividualScaleTier2PlanAnnualy = "price_1QePODLI14x6Jehm1K3sFVur",
  IndividualScaleTier3PlanMonthly = "price_1QeWzaLI14x6JehmSardCeu3",
  IndividualScaleTier3PlanAnnualy = "price_1QeWs3LI14x6Jehmc44kIe0O",
  IndividualScaleTier4PlanMonthly = "price_1QeWzmLI14x6JehmToBv4FB7",
  IndividualScaleTier4PlanAnnualy = "price_1QeWsVLI14x6JehmohZoE2ZI",
  IndividualScaleTier5PlanMonthly = "price_1QeX01LI14x6Jehm92MdsCc5",
  IndividualScaleTier5PlanAnnualy = "price_1QeWtKLI14x6Jehm14MJsoKN",
  ApiStandardPlan = "price_1O9AD7LI14x6Jehmobvvbqro",
  OneTimePayment = "price_1Q8DW1LI14x6JehmYZNuztrW",
  TeamStandardPlan = "price_1PcuGQLI14x6JehmdoIsJ7y2",
}

export function getAppSubscriptionPlanTypeFromStripePriceId(priceId: string) {
  if (
    priceId === StripeSubscriptionPriceId.IndividualScaleTier1PlanMonthly ||
    priceId === StripeSubscriptionPriceId.IndividualScaleTier1PlanAnnualy ||
    priceId === StripeSubscriptionPriceId.IndividualScalePlanMonthly ||
    priceId === StripeSubscriptionPriceId.IndividualScalePlanAnnualy
  ) {
    return AppSubscriptionPlanType.Scale;
  } else if (
    priceId === StripeSubscriptionPriceId.IndividualScaleTier2PlanMonthly ||
    priceId === StripeSubscriptionPriceId.IndividualScaleTier2PlanAnnualy
  ) {
    return AppSubscriptionPlanType.ScaleTier2;
  } else if (
    priceId === StripeSubscriptionPriceId.IndividualScaleTier3PlanMonthly ||
    priceId === StripeSubscriptionPriceId.IndividualScaleTier3PlanAnnualy
  ) {
    return AppSubscriptionPlanType.ScaleTier3;
  } else if (
    priceId === StripeSubscriptionPriceId.IndividualScaleTier4PlanMonthly ||
    priceId === StripeSubscriptionPriceId.IndividualScaleTier4PlanAnnualy
  ) {
    return AppSubscriptionPlanType.ScaleTier4;
  } else if (
    priceId === StripeSubscriptionPriceId.IndividualScaleTier5PlanMonthly ||
    priceId === StripeSubscriptionPriceId.IndividualScaleTier5PlanAnnualy
  ) {
    return AppSubscriptionPlanType.ScaleTier5;
  } else if (
    priceId === StripeSubscriptionPriceId.IndividualProPlanV2 ||
    priceId === StripeSubscriptionPriceId.IndividualProPlanV2Annually ||
    priceId === StripeSubscriptionPriceId.IndividualProPlan ||
    priceId === StripeSubscriptionPriceId.IndividualProPlanAnnually
  ) {
    return AppSubscriptionPlanType.Pro;
  } else if (
    priceId === StripeSubscriptionPriceId.IndividualProPlusPlanV2 ||
    priceId === StripeSubscriptionPriceId.IndividualProPlusPlanV2Annually ||
    priceId === StripeSubscriptionPriceId.IndividualProPlusPlan ||
    priceId === StripeSubscriptionPriceId.IndividualProPlusPlanAnnually
  ) {
    return AppSubscriptionPlanType.ProPlus;
  }

  return AppSubscriptionPlanType.Free;
}

export type StripeSubscriptionPriceIdAnnually =
  | StripeSubscriptionPriceId.IndividualProPlanAnnually
  | StripeSubscriptionPriceId.IndividualProPlusPlanAnnually
  | StripeSubscriptionPriceId.IndividualScalePlanAnnualy
  | StripeSubscriptionPriceId.IndividualProPlanV2Annually
  | StripeSubscriptionPriceId.IndividualProPlusPlanV2Annually
  | StripeSubscriptionPriceId.IndividualScaleTier1PlanAnnualy
  | StripeSubscriptionPriceId.IndividualScaleTier2PlanAnnualy
  | StripeSubscriptionPriceId.IndividualScaleTier3PlanAnnualy
  | StripeSubscriptionPriceId.IndividualScaleTier4PlanAnnualy
  | StripeSubscriptionPriceId.IndividualScaleTier5PlanAnnualy;

export function isStripeSubscriptionPriceIdAnnually(
  priceId: any,
): priceId is StripeSubscriptionPriceIdAnnually {
  return (
    priceId === StripeSubscriptionPriceId.IndividualProPlanAnnually ||
    priceId === StripeSubscriptionPriceId.IndividualProPlusPlanAnnually ||
    priceId === StripeSubscriptionPriceId.IndividualScalePlanAnnualy ||
    priceId === StripeSubscriptionPriceId.IndividualProPlanV2Annually ||
    priceId === StripeSubscriptionPriceId.IndividualProPlusPlanV2Annually ||
    priceId === StripeSubscriptionPriceId.IndividualScaleTier1PlanAnnualy ||
    priceId === StripeSubscriptionPriceId.IndividualScaleTier2PlanAnnualy ||
    priceId === StripeSubscriptionPriceId.IndividualScaleTier3PlanAnnualy ||
    priceId === StripeSubscriptionPriceId.IndividualScaleTier4PlanAnnualy ||
    priceId === StripeSubscriptionPriceId.IndividualScaleTier5PlanAnnualy
  );
}

export enum StripeSubscriptionProductId {
  IndividualProPlan = "prod_NBRGNzZxW8R533",
  IndividualProPlusPlan = "prod_PbavOeZrnOyekM",
  IndividualScalePlan = "prod_Qz5XUjJhGKvi09",
  ProPlanV2 = "prod_RXcCstLStopxel",
  ProPlusPlanV2 = "prod_RXcCHqnbbJOZcq",
  ScaleTier1Plan = "prod_RXc3TgXqqfC6KH",
  ScaleTier2Plan = "prod_RXUMseNyFm3Ri0",
  ScaleTier3Plan = "prod_RXc6pDTk2rZFb0",
  ScaleTier4Plan = "prod_RXc6eediVciYpP",
  ScaleTier5Plan = "prod_RXc7MzIMWEcUmm",
  ApiStandardPlan = "prod_Ox4LrgcmOcKUig",
  TeamStandardPlan = "prod_QTs0FsJABxpXME",
  OneTimePayment = "prod_QzfxZ3cXQBMnBt",
}

export interface StripeSubscriptionLineItem {
  price: StripeSubscriptionPriceId;
  quantity?: number;
}

export function isStripeSubscriptionPriceId(value: any): value is StripeSubscriptionPriceId {
  return Object.values(StripeSubscriptionPriceId).includes(value);
}

export function isStripeSubscriptionProductId(value: any): value is StripeSubscriptionProductId {
  return Object.values(StripeSubscriptionProductId).includes(value);
}

export const subscriptionPlanToProduct: Record<
  AppSubscriptionPlanType,
  StripeSubscriptionProductId | undefined
> = {
  [AppSubscriptionPlanType.Free]: undefined,
  [AppSubscriptionPlanType.Pro]: StripeSubscriptionProductId.ProPlanV2,
  [AppSubscriptionPlanType.ProPlus]: StripeSubscriptionProductId.ProPlusPlanV2,
  [AppSubscriptionPlanType.Scale]: StripeSubscriptionProductId.ScaleTier1Plan,
  [AppSubscriptionPlanType.ScaleTier2]: StripeSubscriptionProductId.ScaleTier2Plan,
  [AppSubscriptionPlanType.ScaleTier3]: StripeSubscriptionProductId.ScaleTier3Plan,
  [AppSubscriptionPlanType.ScaleTier4]: StripeSubscriptionProductId.ScaleTier4Plan,
  [AppSubscriptionPlanType.ScaleTier5]: StripeSubscriptionProductId.ScaleTier5Plan,
  [AppSubscriptionPlanType.Enterprise]: undefined,
};

export const subscriptionProductToPlan: Record<
  StripeSubscriptionProductId,
  AppSubscriptionPlanType | undefined
> = {
  [StripeSubscriptionProductId.IndividualProPlan]: AppSubscriptionPlanType.Pro,
  [StripeSubscriptionProductId.IndividualProPlusPlan]: AppSubscriptionPlanType.ProPlus,
  [StripeSubscriptionProductId.IndividualScalePlan]: AppSubscriptionPlanType.Scale,
  [StripeSubscriptionProductId.ProPlanV2]: AppSubscriptionPlanType.Pro,
  [StripeSubscriptionProductId.ProPlusPlanV2]: AppSubscriptionPlanType.ProPlus,
  [StripeSubscriptionProductId.ScaleTier1Plan]: AppSubscriptionPlanType.Scale,
  [StripeSubscriptionProductId.ScaleTier2Plan]: AppSubscriptionPlanType.ScaleTier2,
  [StripeSubscriptionProductId.ScaleTier3Plan]: AppSubscriptionPlanType.ScaleTier3,
  [StripeSubscriptionProductId.ScaleTier4Plan]: AppSubscriptionPlanType.ScaleTier4,
  [StripeSubscriptionProductId.ScaleTier5Plan]: AppSubscriptionPlanType.ScaleTier5,
  [StripeSubscriptionProductId.ApiStandardPlan]: undefined,
  [StripeSubscriptionProductId.TeamStandardPlan]: AppSubscriptionPlanType.Pro,
  [StripeSubscriptionProductId.OneTimePayment]: undefined,
};

export const subscriptionPlanToPriceMonthly = {
  [AppSubscriptionPlanType.Free]: undefined,
  [AppSubscriptionPlanType.Pro]: StripeSubscriptionPriceId.IndividualProPlanV2,
  [AppSubscriptionPlanType.ProPlus]: StripeSubscriptionPriceId.IndividualProPlusPlanV2,
  [AppSubscriptionPlanType.Scale]: StripeSubscriptionPriceId.IndividualScaleTier1PlanMonthly,
  [AppSubscriptionPlanType.ScaleTier2]: StripeSubscriptionPriceId.IndividualScaleTier2PlanMonthly,
  [AppSubscriptionPlanType.ScaleTier3]: StripeSubscriptionPriceId.IndividualScaleTier3PlanMonthly,
  [AppSubscriptionPlanType.ScaleTier4]: StripeSubscriptionPriceId.IndividualScaleTier4PlanMonthly,
  [AppSubscriptionPlanType.ScaleTier5]: StripeSubscriptionPriceId.IndividualScaleTier5PlanMonthly,
  [AppSubscriptionPlanType.Enterprise]: undefined,
};

export const subscriptionPlanToPriceAnnually = {
  [AppSubscriptionPlanType.Free]: undefined,
  [AppSubscriptionPlanType.Pro]: StripeSubscriptionPriceId.IndividualProPlanV2Annually,
  [AppSubscriptionPlanType.ProPlus]: StripeSubscriptionPriceId.IndividualProPlusPlanV2Annually,
  [AppSubscriptionPlanType.Scale]: StripeSubscriptionPriceId.IndividualScaleTier1PlanAnnualy,
  [AppSubscriptionPlanType.ScaleTier2]: StripeSubscriptionPriceId.IndividualScaleTier2PlanAnnualy,
  [AppSubscriptionPlanType.ScaleTier3]: StripeSubscriptionPriceId.IndividualScaleTier3PlanAnnualy,
  [AppSubscriptionPlanType.ScaleTier4]: StripeSubscriptionPriceId.IndividualScaleTier4PlanAnnualy,
  [AppSubscriptionPlanType.ScaleTier5]: StripeSubscriptionPriceId.IndividualScaleTier5PlanAnnualy,
  [AppSubscriptionPlanType.Enterprise]: undefined,
};

export const subscriptionPlanRank = {
  [AppSubscriptionPlanType.Free]: 0,
  [AppSubscriptionPlanType.Pro]: 1,
  [AppSubscriptionPlanType.ProPlus]: 2,
  [AppSubscriptionPlanType.Scale]: 3,
  [AppSubscriptionPlanType.ScaleTier2]: 4,
  [AppSubscriptionPlanType.ScaleTier3]: 5,
  [AppSubscriptionPlanType.ScaleTier4]: 6,
  [AppSubscriptionPlanType.ScaleTier5]: 7,
  [AppSubscriptionPlanType.Enterprise]: 8,
};

export function isSubscriptionPlanBetterThan(
  planA: AppSubscriptionPlanType,
  planB: AppSubscriptionPlanType,
) {
  return subscriptionPlanRank[planA] > subscriptionPlanRank[planB];
}

export const subscriptionTierV2ToRecommendedPlan: Record<
  AppUserSubscriptionTierV2,
  AppSubscriptionPlanType
> = {
  [AppUserSubscriptionTierV2.Free]: AppSubscriptionPlanType.ProPlus,
  [AppUserSubscriptionTierV2.Pro]: AppSubscriptionPlanType.ProPlus,
  [AppUserSubscriptionTierV2.ProPlus]: AppSubscriptionPlanType.ProPlus,
};

export const subscriptionPlanToRecommendedPlans: Record<
  AppSubscriptionPlanType,
  AppSubscriptionPlanType[]
> = {
  [AppSubscriptionPlanType.Free]: [
    AppSubscriptionPlanType.ProPlus,
    AppSubscriptionPlanType.Scale,
    AppSubscriptionPlanType.ScaleTier2,
    AppSubscriptionPlanType.ScaleTier3,
    AppSubscriptionPlanType.ScaleTier4,
    AppSubscriptionPlanType.ScaleTier5,
    AppSubscriptionPlanType.Enterprise,
  ],
  [AppSubscriptionPlanType.Pro]: [
    AppSubscriptionPlanType.ProPlus,
    AppSubscriptionPlanType.Scale,
    AppSubscriptionPlanType.ScaleTier2,
    AppSubscriptionPlanType.ScaleTier3,
    AppSubscriptionPlanType.ScaleTier4,
    AppSubscriptionPlanType.ScaleTier5,
    AppSubscriptionPlanType.Enterprise,
  ],
  [AppSubscriptionPlanType.ProPlus]: [
    AppSubscriptionPlanType.Scale,
    AppSubscriptionPlanType.ScaleTier2,
    AppSubscriptionPlanType.ScaleTier3,
    AppSubscriptionPlanType.ScaleTier4,
    AppSubscriptionPlanType.ScaleTier5,
    AppSubscriptionPlanType.Enterprise,
  ],
  [AppSubscriptionPlanType.Scale]: [AppSubscriptionPlanType.Enterprise],
  [AppSubscriptionPlanType.ScaleTier2]: [AppSubscriptionPlanType.Enterprise],
  [AppSubscriptionPlanType.ScaleTier3]: [AppSubscriptionPlanType.Enterprise],
  [AppSubscriptionPlanType.ScaleTier4]: [AppSubscriptionPlanType.Enterprise],
  [AppSubscriptionPlanType.ScaleTier5]: [AppSubscriptionPlanType.Enterprise],
  [AppSubscriptionPlanType.Enterprise]: [],
};

export const subscriptionPlanToRecommendedPlan: Record<
  AppSubscriptionPlanType,
  AppSubscriptionPlanType | undefined
> = Object.fromEntries(
  Object.entries(subscriptionPlanToRecommendedPlans).map(([plan, recommendedPlans]) => [
    plan,
    recommendedPlans[0],
  ]),
) as Record<AppSubscriptionPlanType, AppSubscriptionPlanType | undefined>;

export const subscriptionTierV2ToPlan: Record<AppUserSubscriptionTierV2, AppSubscriptionPlanType> =
  {
    [AppUserSubscriptionTierV2.Free]: AppSubscriptionPlanType.Free,
    [AppUserSubscriptionTierV2.Pro]: AppSubscriptionPlanType.Pro,
    [AppUserSubscriptionTierV2.ProPlus]: AppSubscriptionPlanType.ProPlus,
  };
