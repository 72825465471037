import { classNames } from "@/core/utils/classname-utils";
import React from "react";
import {
  CancelButtonClassNameActive,
  CancelButtonClassNameLoading,
  CancelButtonOutlineClassName,
  CommonButtonClassName,
  DropdownMenuItemClassName,
  OutputItemToolbarButtonClassName,
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameError,
  PrimaryButtonClassNameLoading,
  SecondaryButtonClassName,
  SecondaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
  TextButtonClassName,
  TextButtonClassNameDisabled,
} from "./styles";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  error?: boolean;
  inactive?: boolean;
  outline?: boolean;
}

export const PrimaryButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function PrimaryButton(
    { children, loading, disabled, error, className = "", inactive, ...props },
    ref,
  ) {
    const buttonClassName = classNames(
      loading
        ? PrimaryButtonClassNameLoading
        : disabled
          ? PrimaryButtonClassNameDisabled
          : error
            ? PrimaryButtonClassNameError
            : PrimaryButtonClassName,
      className,
    );

    return (
      <button className={buttonClassName} disabled={disabled || loading} ref={ref} {...props}>
        {children}
      </button>
    );
  },
);

export const SecondaryButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function SecondaryButton({ children, disabled, inactive, className = "", ...props }, ref) {
    const buttonClassName = classNames(
      disabled
        ? SecondaryButtonClassNameDisabled
        : inactive
          ? SecondaryButtonClassNameInactive
          : SecondaryButtonClassName,
      className,
    );

    return (
      <button className={buttonClassName} disabled={disabled} ref={ref} {...props}>
        {children}
      </button>
    );
  },
);

export const CancelButton = React.forwardRef<HTMLButtonElement, ButtonProps>(function CancelButton(
  { children, loading, disabled, outline, className = "", ...props },
  ref,
) {
  const buttonClassName = classNames(
    loading
      ? CancelButtonClassNameLoading
      : outline
        ? CancelButtonOutlineClassName
        : CancelButtonClassNameActive,
    className,
  );

  return (
    <button className={buttonClassName} disabled={disabled || loading} ref={ref} {...props}>
      {children}
    </button>
  );
});

export const TextButton = React.forwardRef<HTMLButtonElement, ButtonProps>(function TextButton(
  { children, disabled, className = "", ...props },
  ref,
) {
  const buttonClassName = classNames(
    disabled ? TextButtonClassNameDisabled : TextButtonClassName,
    className,
  );

  return (
    <button className={buttonClassName} disabled={disabled} ref={ref} {...props}>
      {children}
    </button>
  );
});

export const CommonButton = React.forwardRef<HTMLButtonElement, ButtonProps>(function CommonButton(
  { children, disabled, className = "", ...props },
  ref,
) {
  const buttonClassName = classNames(CommonButtonClassName, className);

  return (
    <button className={buttonClassName} disabled={disabled} ref={ref} {...props}>
      {children}
    </button>
  );
});

export const OutputItemToolbarButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function OutputItemToolbarButton({ children, disabled, className = "", ...props }, ref) {
    const buttonClassName = classNames(OutputItemToolbarButtonClassName, className);

    return (
      <button className={buttonClassName} disabled={disabled} ref={ref} {...props}>
        {children}
      </button>
    );
  },
);

export const DropdownMenuItem = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function DropdownMenuItem({ children, disabled, className = "", ...props }, ref) {
    const buttonClassName = classNames(DropdownMenuItemClassName, className);

    return (
      <button className={buttonClassName} disabled={disabled} ref={ref} {...props}>
        {children}
      </button>
    );
  },
);
