import { getUpdaterFunction, SetEditorStateFunction } from "@/contexts/editor-context-utils";
import { noop } from "lodash";
import { StateUpdater } from "./utils";

export interface UpscaleV2EditorState {
  upscaleV2TargetLength: number;
  setUpscaleV2TargetLength: (value: StateUpdater<number>) => void;
  upscaleV2Creativity: number;
  setUpscaleV2Creativity: (value: StateUpdater<number>) => void;
}

export function getDummyUpscaleV2EditorState(): UpscaleV2EditorState {
  return {
    upscaleV2TargetLength: 2048,
    setUpscaleV2TargetLength: noop,
    upscaleV2Creativity: 0.2,
    setUpscaleV2Creativity: noop,
  };
}

export function getDefaultUpscaleV2EditorState(set: SetEditorStateFunction): UpscaleV2EditorState {
  return {
    upscaleV2TargetLength: 2048,
    setUpscaleV2TargetLength: getUpdaterFunction(set, "upscaleV2TargetLength"),
    upscaleV2Creativity: 0.2,
    setUpscaleV2Creativity: getUpdaterFunction(set, "upscaleV2Creativity"),
  };
}

export function resetUpscaleV2EditorState(state: UpscaleV2EditorState) {
  state.setUpscaleV2TargetLength(2048);
  state.setUpscaleV2Creativity(0.2);
}
