import { editorContextStore } from "@/contexts/editor-context";
import { isPublicUserId } from "@/core/common/types";
import { getDefaultUserTeamMetadata, TeamMetadataCollection } from "@/core/common/types/team";
import { debugError } from "@/core/utils/print-utilts";
import { useCurrentTeamId } from "@/core/utils/team-utils";
import { getObjectKeys } from "@/core/utils/type-utils";
import React from "react";

function useActiveTeamQuotaUpdateEffect() {
  const user = editorContextStore((state) => state.user);
  const backend = editorContextStore((state) => state.backend);
  const teamId = useCurrentTeamId();
  const setTeamQuotas = editorContextStore((state) => state.setTeamQuotas);

  React.useEffect(() => {
    if (!backend || !teamId || !user) {
      return;
    }

    return backend.onPublicTeamQuotasUpdate({
      teamId,
      callback: (teamQuotas) => {
        if (!teamQuotas) {
          debugError(`Team ${teamId} quota is invalid.`);
          return;
        }
        setTeamQuotas(teamQuotas);
      },
    });
  }, [backend, teamId, setTeamQuotas, user]);
}

export function useTeamsUpdateEffect() {
  const backend = editorContextStore((state) => state.backend);
  const publicUserId = editorContextStore((state) => state.publicUserId);
  const setUserTeams = editorContextStore((state) => state.setUserTeams);

  useActiveTeamQuotaUpdateEffect();

  const handleTeamsUpdate = React.useCallback(
    (teamsMetadataCollection: TeamMetadataCollection) => {
      if (!publicUserId) {
        debugError("Cannot handle teams update because the public user id is invalid.");
        return;
      }

      const teamIds = getObjectKeys(teamsMetadataCollection);

      if (teamIds.length > 0) {
        setUserTeams(teamsMetadataCollection);
      } else {
        const defaultTeamMetadata = getDefaultUserTeamMetadata(editorContextStore.getState());
        if (defaultTeamMetadata) {
          setUserTeams({ [defaultTeamMetadata.id]: defaultTeamMetadata });
        }
      }
    },
    [publicUserId, setUserTeams],
  );

  React.useEffect(() => {
    if (!backend || !isPublicUserId(publicUserId)) {
      return () => {
        setUserTeams({});
      };
    }

    backend.getUserTeams(publicUserId).then(handleTeamsUpdate);

    const unsubscribeToUserTeamsUpdate = backend.onUserTeamsUpdate({
      publicUserId,
      callback: handleTeamsUpdate,
    });

    return () => {
      setUserTeams({});
      unsubscribeToUserTeamsUpdate?.();
    };
  }, [backend, publicUserId, handleTeamsUpdate, setUserTeams]);
}
