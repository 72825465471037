import { getUpdaterFunction, type SetEditorStateFunction } from "@/contexts/editor-context-utils";
import { noop } from "lodash";
import type { StateUpdater } from "./utils";

export enum MobileBottomPanelState {
  Hide = "hide",
  // Half = 'half',
  Full = "full",
}

export interface MobileEditorState {
  isMobile: boolean;
  setIsMobile: (value: StateUpdater<boolean>) => void;
  mobileBottomPanelState: MobileBottomPanelState;
  setMobileBottomPanelState: (value: StateUpdater<MobileBottomPanelState>) => void;
  mobileBottomPanelHeight: number;
  setMobileBottomPanelHeight: (value: StateUpdater<number>) => void;
}

export function getDummyMobileEditorState(): MobileEditorState {
  return {
    isMobile: false,
    setIsMobile: noop,
    mobileBottomPanelState: MobileBottomPanelState.Full,
    setMobileBottomPanelState: noop,
    mobileBottomPanelHeight: 300,
    setMobileBottomPanelHeight: noop,
  };
}

export function getDefaultMobileEditorState(set: SetEditorStateFunction): MobileEditorState {
  return {
    isMobile: false,
    setIsMobile: getUpdaterFunction(set, "isMobile"),
    mobileBottomPanelState: MobileBottomPanelState.Full,
    setMobileBottomPanelState: getUpdaterFunction(set, "mobileBottomPanelState"),
    mobileBottomPanelHeight: 0,
    setMobileBottomPanelHeight: getUpdaterFunction(set, "mobileBottomPanelHeight"),
  };
}
