import {
  DropdownClassName,
  SecondaryButtonClassNameInactive,
} from "@/components/constants/class-names";
import { ImageComponentPrimitive } from "@/components/utils/image";
import { editorContextStore } from "@/contexts/editor-context";
import { classNames } from "@/core/utils/classname-utils";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import * as Tooltip from "@radix-ui/react-tooltip";
import React from "react";
import styles from "./loading-card.module.css";

export function LeftPanelImageGridItemPlaceholder({
  ratio = 1,
  hasStyles = false,
}: {
  ratio?: number;
  hasStyles?: boolean;
}) {
  return (
    <AspectRatio.Root
      ratio={ratio}
      className={classNames(
        "relative w-full overflow-hidden p-0",
        hasStyles ? SecondaryButtonClassNameInactive : "",
        hasStyles ? styles.Card : "",
      )}
    />
  );
}

export const LeftPanelImageGridItem = React.forwardRef(
  (
    {
      id,
      imgSrc,
      imgAlt,
      imageProps,
      imgClassName,
      onClick,
      hoverCardContent,
      ratio = 1,
      sideOffset = 8,
      className = "",
      draggable = false,
      onDragEnd,
      ...props
    }: Tooltip.TooltipProps & {
      id?: string;
      imgSrc?: string;
      imgAlt?: string;
      imgClassName?: string;
      imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
      onClick?: React.MouseEventHandler<HTMLDivElement>;
      hoverCardContent?: React.ReactNode;
      ratio?: number;
      sideOffset?: number;
      className?: string;
      draggable?: boolean;
      onDragEnd?: React.DragEventHandler<HTMLDivElement>;
    },
    forwardedRef: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const [isImageLoaded, setImageLoaded] = React.useState(false);
    const editor = editorContextStore((state) => state.editor);

    return (
      <Tooltip.Root {...props}>
        <Tooltip.Trigger ref={forwardedRef}>
          <AspectRatio.Root
            id={id}
            ratio={ratio}
            draggable={draggable}
            className={classNames(
              SecondaryButtonClassNameInactive,
              "relative w-full overflow-hidden p-0",
              styles.Card,
              isImageLoaded ? "" : styles.IsLoading,
              className ?? "",
            )}
            style={{
              padding: 0,
            }}
            onClick={onClick}
            onDragEnd={onDragEnd}
          >
            <ImageComponentPrimitive
              alt={imgAlt}
              className={imgClassName || "object-cover w-full h-full"}
              src={imgSrc}
              isImageLoaded={isImageLoaded}
              setImageLoaded={setImageLoaded}
              {...imageProps}
            />
          </AspectRatio.Root>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className={classNames(
              DropdownClassName,
              "flex flex-col text-zinc-300 text-sm w-[268px] pointer-events-none",
            )}
            side="bottom"
            sideOffset={sideOffset}
          >
            {hoverCardContent}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    );
  },
);
