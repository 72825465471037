import { MultiStageGenerationInput } from "@/backend/custom-model-post-process";
import { getUpdaterFunction, SetEditorStateFunction } from "@/contexts/editor-context-utils";
import { PublicUserId, PublicUserRoles, StateUpdater } from "@/core/common/types";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { Timestamp } from "firebase/firestore";
import { clamp, noop } from "lodash";
import { PublicTeamId } from "./team";

export enum CustomModelEditorTab {
  Train = "train",
  Generate = "generate",
}

export type CustomModelDatasetItemStatus = "queued" | "processing" | "finished";

export type CustomModelDataset = Record<string, CustomModelDatasetItem>;

export enum CustomModelType {
  Product = "Product",
  Fashion = "Fashion",
  Style = "Style",
  Face = "Face",
  Custom = "Custom",
  Furniture = "Furniture",
  Tech = "Tech",
  Food = "Food",
  Vase = "Vase",
  VirtualModel = "VirtualModel",
  Footwear = "Footwear",
  Jewelry = "Jewelry",
  Bags = "Bags",
  BrandA = "BrandA",
  BrandB = "BrandB",
}

export enum FrontendDisplayTemplateType {
  Product = "Product",
  Fashion = "Fashion",
  Style = "Style",
  Face = "Face",
  Custom = "Custom",
  Furniture = "Furniture",
  Tech = "Tech",
  Food = "Food",
  Vase = "Vase",
  VirtualModel = "VirtualModel",
  Footwear = "Footwear",
  Jewelry = "Jewelry",
  Bags = "Bags",
  BrandA = "BrandA",
  BrandB = "BrandB",
  HomeDecor = "HomeDecor",
  Toys = "Toys",
  Hats = "Hats",
  OfficeSupplies = "OfficeSupplies",
  Kitchenware = "Kitchenware",
  Vehicles = "Vehicles",
  MensFashion = "MensFashion",
  Dresses = "Dresses",
  Outerwear = "Outerwear",
  Glasses = "Glasses",
  Watches = "Watches",
  Sweaters = "Sweaters",
}

const frontendDisplayTemplateTypeToCustomModelTypeMap: Record<
  FrontendDisplayTemplateType,
  CustomModelType
> = {
  [FrontendDisplayTemplateType.Product]: CustomModelType.Product,
  [FrontendDisplayTemplateType.Fashion]: CustomModelType.Fashion,
  [FrontendDisplayTemplateType.Style]: CustomModelType.Style,
  [FrontendDisplayTemplateType.Face]: CustomModelType.Face,
  [FrontendDisplayTemplateType.Custom]: CustomModelType.Custom,
  [FrontendDisplayTemplateType.Furniture]: CustomModelType.Furniture,
  [FrontendDisplayTemplateType.Tech]: CustomModelType.Tech,
  [FrontendDisplayTemplateType.Food]: CustomModelType.Food,
  [FrontendDisplayTemplateType.Vase]: CustomModelType.Vase,
  [FrontendDisplayTemplateType.VirtualModel]: CustomModelType.VirtualModel,
  [FrontendDisplayTemplateType.Footwear]: CustomModelType.Footwear,
  [FrontendDisplayTemplateType.Jewelry]: CustomModelType.Jewelry,
  [FrontendDisplayTemplateType.Bags]: CustomModelType.Bags,
  [FrontendDisplayTemplateType.BrandA]: CustomModelType.BrandA,
  [FrontendDisplayTemplateType.BrandB]: CustomModelType.BrandB,
  [FrontendDisplayTemplateType.HomeDecor]: CustomModelType.Product,
  [FrontendDisplayTemplateType.Toys]: CustomModelType.Product,
  [FrontendDisplayTemplateType.Hats]: CustomModelType.Fashion,
  [FrontendDisplayTemplateType.OfficeSupplies]: CustomModelType.Product,
  [FrontendDisplayTemplateType.Kitchenware]: CustomModelType.Product,
  [FrontendDisplayTemplateType.Vehicles]: CustomModelType.Product,
  [FrontendDisplayTemplateType.MensFashion]: CustomModelType.Fashion,
  [FrontendDisplayTemplateType.Dresses]: CustomModelType.Fashion,
  [FrontendDisplayTemplateType.Outerwear]: CustomModelType.Fashion,
  [FrontendDisplayTemplateType.Glasses]: CustomModelType.Fashion,
  [FrontendDisplayTemplateType.Watches]: CustomModelType.Fashion,
  [FrontendDisplayTemplateType.Sweaters]: CustomModelType.Fashion,
};

export const customModelTypeToFrontEndDisplayTemplateTypeMap: Record<
  CustomModelType,
  FrontendDisplayTemplateType
> = {
  [CustomModelType.Product]: FrontendDisplayTemplateType.Product,
  [CustomModelType.Fashion]: FrontendDisplayTemplateType.Fashion,
  [CustomModelType.Style]: FrontendDisplayTemplateType.Style,
  [CustomModelType.Face]: FrontendDisplayTemplateType.Face,
  [CustomModelType.Custom]: FrontendDisplayTemplateType.Custom,
  [CustomModelType.Furniture]: FrontendDisplayTemplateType.Furniture,
  [CustomModelType.Tech]: FrontendDisplayTemplateType.Tech,
  [CustomModelType.Food]: FrontendDisplayTemplateType.Food,
  [CustomModelType.Vase]: FrontendDisplayTemplateType.Vase,
  [CustomModelType.VirtualModel]: FrontendDisplayTemplateType.VirtualModel,
  [CustomModelType.Footwear]: FrontendDisplayTemplateType.Footwear,
  [CustomModelType.Jewelry]: FrontendDisplayTemplateType.Jewelry,
  [CustomModelType.Bags]: FrontendDisplayTemplateType.Bags,
  [CustomModelType.BrandA]: FrontendDisplayTemplateType.BrandA,
  [CustomModelType.BrandB]: FrontendDisplayTemplateType.BrandB,
};

export function isFrontendDisplayTemplateType(value: any): value is FrontendDisplayTemplateType {
  return Object.values(FrontendDisplayTemplateType).includes(value);
}

export function isCustomModelType(value: any): value is CustomModelType {
  return Object.values(CustomModelType).includes(value);
}

export function getCustomModelTypeFromFrontendDisplayTemplateType(
  displayTemplateType: FrontendDisplayTemplateType | CustomModelType,
): CustomModelType {
  if (isCustomModelType(displayTemplateType)) {
    return displayTemplateType;
  }
  return frontendDisplayTemplateTypeToCustomModelTypeMap[displayTemplateType];
}

export function getFrontendDisplayTemplateTypeFromCustomModelType(
  customModelType: CustomModelType,
) {
  return customModelTypeToFrontEndDisplayTemplateTypeMap[customModelType];
}

export function getCustomModelWorkflowFromCustomModelInfo(customModelInfo: {
  frontendDisplayTemplateType?: FrontendDisplayTemplateType;
  customModelType?: CustomModelType;
}): FrontendDisplayTemplateType {
  return (
    customModelInfo?.frontendDisplayTemplateType ||
    (customModelInfo?.customModelType &&
      getFrontendDisplayTemplateTypeFromCustomModelType(customModelInfo?.customModelType)) ||
    FrontendDisplayTemplateType.Custom
  );
}

export enum CustomModelTrainingContentOrStyle {
  Content = "content",
  Style = "style",
  Balanced = "balanced",
}

export interface CustomModelInfo {
  id: string;
  customModelType?: CustomModelType;
  frontendDisplayTemplateType?: FrontendDisplayTemplateType;
  displayName?: string;
  timeCreated: Timestamp;
  timeModified: Timestamp;
  isDeleted: boolean;
  publicTeamId: PublicTeamId;
  roles: PublicUserRoles;
  thumbnailStoragePath?: string;
  defaultPredictionItem?: Pick<CustomModelPredictionItem, "input" | "output" | "usedModels">;
}

export function isCustomModelInfo(obj: any): obj is CustomModelInfo {
  return (
    obj != null &&
    typeof obj.id === "string" &&
    (typeof obj.displayName === "undefined" || typeof obj.displayName === "string") &&
    typeof obj.roles === "object" &&
    obj.roles != null
  );
}

export enum MultiDatasetTag {
  Product = "Product",
  Human = "Human",
  Other = "Other",
}

export interface CustomModelDatasetItem {
  id: string;
  caption?: string;
  storagePath: string;
  timeCreated: Timestamp;
  timeModified: Timestamp;
  multiDatasetTag?: MultiDatasetTag;
}

export function isCustomModelDatasetItem(obj: any): obj is CustomModelDatasetItem {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.id === "string" &&
    typeof obj.storagePath === "string"
  );
}

export enum CustomModelTrainingStatus {
  Starting = "starting",
  Processing = "processing",
  GenericHardwareCheckpointPaused = "paused",
  Succeeded = "succeeded",
  Failed = "failed",
  Canceled = "canceled",
}

const activeCustomModelTrainingStatuses = new Set([
  CustomModelTrainingStatus.Processing,
  CustomModelTrainingStatus.Starting,
  CustomModelTrainingStatus.GenericHardwareCheckpointPaused,
]);

export function isCustomModelTrainingStatusActive(status?: CustomModelTrainingStatus) {
  return status && activeCustomModelTrainingStatuses.has(status);
}

export enum CustomModelTrainingBackendType {
  GenericHardware = "R", // This used to be Replicate but now we support many types of hardware
  Fal = "F",
}

export function isHighQualityTrainingBackendType(backendType: CustomModelTrainingBackendType) {
  return backendType === CustomModelTrainingBackendType.GenericHardware;
}

export function isFastTrainingBackendType(backendType: CustomModelTrainingBackendType) {
  return backendType === CustomModelTrainingBackendType.Fal;
}

export const customModelTrainingBackendToDisplayName: Record<
  CustomModelTrainingBackendType,
  string
> = {
  [CustomModelTrainingBackendType.GenericHardware]: "Standard speed",
  [CustomModelTrainingBackendType.Fal]: "Fast",
};

export interface CustomModelTrainingInputFalSpecific {
  backendType: CustomModelTrainingBackendType.Fal;
  trigger_word: string;
  iter_multiplier?: number;
  is_style?: boolean;
  is_input_format_already_preprocessed?: boolean;
  modelId?: string;
  trainingStrengthPercent: number;
}

export function isCustomModelTrainingInputFalSpecific(
  obj: any,
): obj is CustomModelTrainingInputFalSpecific {
  return obj != null && typeof obj.trigger_word === "string";
}

// export interface CustomModelTrainingInputHighQualityBase {
//   steps: number;
//   batch_size: number;
//   autocaption: boolean;
//   trigger_word: string;
//   learning_rate: number;
//   input_images?: string;
//   modelId?: string;
//   trainingId?: string;
//   trainingStrengthPercent: number;
//   content_or_style?: CustomModelTrainingContentOrStyle; // does nothing on replicate but apparently the ostris trainer still has this. content for is for products and presumably style is for general vibes
// }

/** used to have other types but they all got merged into generic hardware */
export interface CustomModelTrainingInputGenericHardwareSpecific {
  backendType: CustomModelTrainingBackendType.GenericHardware;
  steps: number;
  batch_size: number;
  autocaption?: boolean;
  trigger_word: string;
  learning_rate: number;
  content_or_style?: CustomModelTrainingContentOrStyle; // does nothing on replicate but apparently the ostris trainer still has this. content for is for products and presumably style is for general vibes
  trainingId?: string;
  trainingStrengthPercent: number;
  modelId?: string; // not used, just makes the types work.
}

export type CustomModelTrainingInput =
  | CustomModelTrainingInputFalSpecific
  | CustomModelTrainingInputGenericHardwareSpecific;

export function getDefaultCustomModelTrainingInput(): CustomModelTrainingInput {
  return {
    backendType: CustomModelTrainingBackendType.GenericHardware,
    steps: 1000,
    batch_size: 1,
    autocaption: true,
    trigger_word: "TOK",
    learning_rate: 0.0004,
    trainingStrengthPercent: 0.5,
  };
}

export type CustomModelScaleConfig = {
  scale: number;
  modelId: string;
  trainingId: string;
};

export type CustomModelScaleConfigs = Record<string, CustomModelScaleConfig>;

export function correctCustomModelScaleConfigs(
  scaleConfigs: Partial<CustomModelScaleConfigs>,
): CustomModelScaleConfigs {
  return Object.entries(scaleConfigs).reduce((acc, [key, partialConfig]) => {
    if (!partialConfig) {
      return acc;
    }
    const { modelId, trainingId } = partialConfig;
    let { scale } = partialConfig;

    // Validate modelId and trainingId
    if (typeof modelId !== "string" || !modelId.trim()) {
      return acc;
    }
    if (typeof trainingId !== "string" || !trainingId.trim()) {
      return acc;
    }

    // Validate and clamp scale (default to 1 if invalid)
    if (typeof scale !== "number" || isNaN(scale)) {
      scale = 1;
    }
    scale = Math.max(0, Math.min(2, scale));

    // Discard if scale is 0
    if (scale === 0) {
      return acc;
    }

    acc[key] = { scale, modelId, trainingId };
    return acc;
  }, {} as CustomModelScaleConfigs);
}

export interface CustomModelPlaygroundPromptEditorState {
  json: string;
  text: string;
  scaleConfigs: CustomModelScaleConfigs;
  isUpdatingPrompt: boolean;
}

export enum CustomModelPredictionInputBackendType {
  SelfHost = "S",
  Fal = "F",
  FixDetail = "FixDetail",
  RegenerateHuman = "RegenerateHuman",
  UpscaleCreative = "UpscaleCreative",
  ClarityUpscale = "UpscaleCreativeV2",
  GenerateVariations = "GenerateVariations",
  InContextVariations = "ICV",
  MultiStageGeneration = "MultiStageGeneration",
}

export interface ImageSizeFalType {
  width: number;
  height: number;
}

export enum OutputFormatFalType {
  JPEG = "jpeg",
  PNG = "png",
}

export interface CustomModelPredictionInputFal {
  backendType: CustomModelPredictionInputBackendType.Fal;
  scaleConfigs: CustomModelScaleConfigs;
  prompt: string;
  promptJson?: string;
  promptEditorState?: string;
  seed?: number;
  sync_mode?: boolean;
  image_size?: ImageSizeFalType;
  num_images?: number;
  output_format?: OutputFormatFalType;
  guidance_scale?: number;
  num_inference_steps?: number;
  enable_safety_checker?: boolean;
}

export function isCustomModelPredictionInputFal(
  input: any,
): input is CustomModelPredictionInputFal {
  return (
    input &&
    input.backendType === CustomModelPredictionInputBackendType.Fal &&
    typeof input.prompt === "string" &&
    input.scaleConfigs != null
  );
}

export interface CustomModelPredictionInputMultiStageGeneration extends MultiStageGenerationInput {
  backendType: CustomModelPredictionInputBackendType.MultiStageGeneration;
}

export function isCustomModelPredictionInputMultiStageGeneration(
  input: any,
): input is CustomModelPredictionInputMultiStageGeneration {
  return (
    input &&
    (input as CustomModelPredictionInputMultiStageGeneration).backendType ===
      CustomModelPredictionInputBackendType.MultiStageGeneration
  );
}

export interface CustomModelPredictionInputSelfHost {
  backendType: CustomModelPredictionInputBackendType.SelfHost;
  width: number;
  height: number;
  prompt: string;
  promptJson?: string;
  promptEditorState?: string;
  lora_scale?: number;
  num_outputs: number;
  aspect_ratio?: string;
  output_format?: string;
  guidance_scale: number;
  output_quality?: number;
  num_inference_steps: number;
  scale_configs?: CustomModelScaleConfigs;
}

export function isCustomModelPredictionInputSelfHost(
  input: any,
): input is CustomModelPredictionInputSelfHost {
  return (
    input &&
    typeof input.prompt === "string" &&
    typeof input.width === "number" &&
    typeof input.height === "number" &&
    typeof input.num_inference_steps === "number"
  );
}

export interface CustomModelFixDetailsFalArgsOverride {
  num_inference_steps?: number;
  guidance_scale?: number;
  num_images?: number;
  enable_safety_checker?: boolean;
  output_format?: string;
  strength?: number;
  seed?: number;
}

export interface CustomModelPredictionInputFixDetails {
  backendType: CustomModelPredictionInputBackendType;
  shortCaption: string;
  fullCaption: string;
  promptJson?: string;
  modelTrainingPairs: CustomModelScaleConfigs;
  extraPredictionArgs?: CustomModelFixDetailsFalArgsOverride;
  image_size?: ImageSizeFalType;
}

export function isCustomModelPredictionInputFixDetails(
  input: any,
): input is CustomModelPredictionInputFixDetails {
  return (
    typeof input === "object" &&
    input !== null &&
    "backendType" in input &&
    typeof input.backendType === "string" &&
    "shortCaption" in input &&
    typeof input.shortCaption === "string" &&
    "fullCaption" in input &&
    typeof input.fullCaption === "string" &&
    "modelTrainingPairs" in input &&
    typeof input.modelTrainingPairs === "object"
  );
}
export type CustomModelPredictionInput =
  | CustomModelPredictionInputFal
  | CustomModelPredictionInputSelfHost
  | CustomModelPredictionInputFixDetails;

export function getCustomModelScaleConfigsFromCustomModelPredictionInput(
  input: CustomModelPredictionInput,
): CustomModelScaleConfigs | undefined {
  if (isCustomModelPredictionInputFal(input)) {
    return input.scaleConfigs;
  } else if (isCustomModelPredictionInputSelfHost(input)) {
    return input.scale_configs;
  } else {
    return input.modelTrainingPairs;
  }
}

function clampImageLength(length: number) {
  try {
    return clamp(length, 128, 8192);
  } catch (error) {
    debugError("Error clamping image length: ", error);
    return 1024;
  }
}

export function getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(
  customModelInput: CustomModelPredictionInput,
): CustomModelPredictionInputSelfHost {
  if (isCustomModelPredictionInputSelfHost(customModelInput)) {
    return customModelInput;
  }

  if (isCustomModelPredictionInputFal(customModelInput)) {
    return {
      backendType: CustomModelPredictionInputBackendType.SelfHost,
      width: clampImageLength(customModelInput.image_size?.width ?? 1024),
      height: clampImageLength(customModelInput.image_size?.height ?? 1024),
      prompt: customModelInput.prompt,
      promptJson: customModelInput.promptJson,
      promptEditorState: customModelInput.promptEditorState,
      num_outputs: customModelInput.num_images ?? 1,
      num_inference_steps: customModelInput.num_inference_steps ?? 28,
      guidance_scale: customModelInput.guidance_scale ?? 3.5,
      scale_configs: customModelInput.scaleConfigs,
    };
  }

  if (isCustomModelPredictionInputMultiStageGeneration(customModelInput)) {
    return {
      backendType: CustomModelPredictionInputBackendType.SelfHost,
      width: clampImageLength(customModelInput.imageSize?.width ?? 1024),
      height: clampImageLength(customModelInput.imageSize?.height ?? 1024),
      prompt: customModelInput.prompt ?? "",
      promptJson: customModelInput.promptJson,
      num_outputs: customModelInput.numImages ?? 1,
      num_inference_steps: 28,
      guidance_scale: 3.5,
      scale_configs: customModelInput.customModelScaleConfigs ?? {},
    };
  }

  return {
    backendType: CustomModelPredictionInputBackendType.SelfHost,
    width: clampImageLength(customModelInput.image_size?.width ?? 1024),
    height: clampImageLength(customModelInput.image_size?.height ?? 1024),
    prompt: customModelInput.fullCaption,
    promptJson: customModelInput.promptJson,
    num_outputs: customModelInput.extraPredictionArgs?.num_images ?? 1,
    num_inference_steps: customModelInput.extraPredictionArgs?.num_inference_steps ?? 0,
    guidance_scale: customModelInput.extraPredictionArgs?.guidance_scale ?? 3.5,
    scale_configs: customModelInput.modelTrainingPairs,
  };
}

export function getImageSizeFromCustomModelPredictionInput(
  customModelInput: CustomModelPredictionInput,
): { width: number; height: number } {
  try {
    if (isCustomModelPredictionInputSelfHost(customModelInput)) {
      return {
        width: customModelInput.width,
        height: customModelInput.height,
      };
    } else {
      const { width = 1, height = 1 } = customModelInput?.image_size ?? {};
      return {
        width,
        height,
      };
    }
  } catch (error) {
    debugLog("Error getting image size from prediction input: ", error);

    return {
      width: 1024,
      height: 1024,
    };
  }
}

export function getImageAspectRatioFromCustomModelPredictionInput(
  customModelInput: CustomModelPredictionInput,
) {
  let aspectRatio = 1;
  if (isCustomModelPredictionInputSelfHost(customModelInput)) {
    aspectRatio = customModelInput.width / customModelInput.height;
  } else {
    const { width = 1, height = 1 } = customModelInput?.image_size ?? {};
    aspectRatio = width / height;
  }
  return clamp(aspectRatio, 0.1, 10);
}

export enum CustomModelAction {
  CreateNewModel = "CreateNewModel",
  StartTraining = "StartTraining",
  StopTraining = "StopTraining",
  StartPrediction = "StartPrediction",
  StopPrediction = "StopPrediction",
}

export interface CustomModelEntrypointAuthArgs {
  publicTeamId: PublicTeamId;
}

export interface HandleCreateCustomModelArgs extends CustomModelEntrypointAuthArgs {
  type: CustomModelAction.CreateNewModel;
  displayName?: string;
  customModelType: CustomModelType;
  frontendDisplayTemplateType?: FrontendDisplayTemplateType;
}

export type HandleCreateCustomModelResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      id: string;
      displayName: string;
      customModelInfo: CustomModelInfo;
    };

export type HandleCustomModelTrainingStopArgs = CustomModelEntrypointAuthArgs & {
  type: CustomModelAction.StopTraining;
  modelId: string;
  trainingId: string;
};

export type HandleCustomModelTrainingStopResponse = {
  ok: boolean;
  message: string;
};

export interface HandleCustomModelTrainingStartArgs extends CustomModelEntrypointAuthArgs {
  type: CustomModelAction.StartTraining;
  modelId: string;
  trainingInput: CustomModelTrainingInput;
}

export type HandleCustomModelTrainingStartResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      newTrainingId: string;
    };

export type HandleStartCustomModelPredictionArgs = CustomModelEntrypointAuthArgs & {
  type: CustomModelAction.StartPrediction;
  input: Partial<CustomModelPredictionInput>;
};

export type HandleStartCustomModelPredictionResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId: string;
    };

export type HandleStopCustomModelPredictionArgs = CustomModelEntrypointAuthArgs & {
  // userId: string,
  type: CustomModelAction.StopPrediction;
  modelId: string;
  predictionId: string;
};

export type HandleStopCustomModelPredictionResponse = {
  ok: boolean;
  message: string;
};

export interface UploadReferenceHumanArgs {
  faceImageStoragePath: string;
}

export interface UploadReferenceHumanFileArgs {
  faceImage: File | Blob;
  publicTeamId: PublicTeamId;
}

/** the actual hardware type that ran the job, if using GenericHardware approach. look at corresponding type in firebase functions in backend for more details */
export enum CustomModelTrainingActualHardwareType {
  Lepton = "L",
  Replicate = "R",
  Salad = "S",
}

export interface CustomModelTrainingItem {
  id: string;
  jobId?: string;
  modelId?: string;
  displayName?: string;
  caption?: string;
  status: CustomModelTrainingStatus;
  input: CustomModelTrainingInput;
  zipFileStoragePath?: string;
  timeCreated: Timestamp;
  timeModified: Timestamp;
  progress: number;
  actualHardwareType?: CustomModelTrainingActualHardwareType; // LEGACY won't have this. in that case, can assume it's R, but has no effect on anything anyways.
  numProactiveHealthchecks?: number;
}

export function isCustomModelTrainingItem(item: any): item is CustomModelTrainingItem {
  return item && typeof item.id === "string" && item.status && item.input;
}

export function getModelTriggerWord({ modelId }: { modelId: string }) {
  return modelId.slice(0, 5).toUpperCase();
}

export interface CustomModelPredictionItem {
  id: string;
  callerPublicUserId: PublicUserId;
  status: CustomModelTrainingStatus;
  input: CustomModelPredictionInput;
  output?: string[];
  usedModels: Record<string, boolean>;
  roles: PublicUserRoles;
  timeCreated: Timestamp;
  timeModified: Timestamp;
  backendTimeStarted?: Timestamp;
  backendTimeCompleted?: Timestamp;
  isDeleted: boolean;
}

export function isCustomModelPredictionItem(item: any): item is CustomModelPredictionItem {
  return item && typeof item.id === "string" && item.status && item.input;
}

export function getDisplayNameFromId(id: string) {
  return id.slice(0, 5);
}

function getTrainingDefaultDisplayName(
  training: Partial<CustomModelTrainingItem> & {
    id: string;
  },
) {
  return `Training-${getDisplayNameFromId(training.id)}`;
}

export function getTrainingDisplayName(
  training: Partial<CustomModelTrainingItem> & {
    id: string;
  },
) {
  return training.displayName || getTrainingDefaultDisplayName(training);
}

export const customModelMentionTrigger = "@";

export const customModelCaptionTrigger = "[trigger]";

export function getModelTrainingMentionName({
  modelDisplayName,
  training,
}: {
  modelDisplayName: string;
  training: {
    displayName?: string;
    id: string;
  };
}) {
  return customModelMentionTrigger + modelDisplayName + "/" + getTrainingDisplayName(training);
}

export function getModelIdFromTraining(training: CustomModelTrainingItem) {
  return training.modelId || training.input?.modelId;
}

export interface CustomModelEditorState {
  customModelId: string | undefined;
  setCustomModelId: (value: StateUpdater<string | undefined>) => void;
  customModels: Record<string, CustomModelInfo>;
  setCustomModels: (value: StateUpdater<Record<string, CustomModelInfo>>) => void;
  publicCustomModels: Record<string, CustomModelInfo>;
  setPublicCustomModels: (value: StateUpdater<Record<string, CustomModelInfo>>) => void;
  customModelInfo: CustomModelInfo | undefined;
  setCustomModelInfo: (value: StateUpdater<CustomModelInfo | undefined>) => void;
  customModelDataset: CustomModelDataset | undefined;
  setCustomModelDataset: (value: StateUpdater<CustomModelDataset | undefined>) => void;
  customModelTrainings: Record<string, CustomModelTrainingItem>;
  setCustomModelTrainings: (value: StateUpdater<Record<string, CustomModelTrainingItem>>) => void;
  customModelWorkflow: FrontendDisplayTemplateType;
  setCustomModelWorkflow: (value: StateUpdater<FrontendDisplayTemplateType>) => void;
  customModelPredictions: Record<string, CustomModelPredictionItem>;
  setCustomModelPredictions: (
    value: StateUpdater<Record<string, CustomModelPredictionItem>>,
  ) => void;
}

export function getDummyCustomModelEditorState(): CustomModelEditorState {
  return {
    customModelId: undefined,
    setCustomModelId: noop,
    customModels: {},
    setCustomModels: noop,
    publicCustomModels: {},
    setPublicCustomModels: noop,
    customModelInfo: undefined,
    setCustomModelInfo: noop,
    customModelDataset: undefined,
    setCustomModelDataset: noop,
    customModelTrainings: {},
    setCustomModelTrainings: noop,
    customModelWorkflow: FrontendDisplayTemplateType.Custom,
    setCustomModelWorkflow: noop,
    customModelPredictions: {},
    setCustomModelPredictions: noop,
  };
}

export function getDefaultCustomModelEditorState(
  set: SetEditorStateFunction,
): CustomModelEditorState {
  return {
    customModelId: undefined,
    setCustomModelId: getUpdaterFunction(set, "customModelId"),
    customModels: {},
    setCustomModels: getUpdaterFunction(set, "customModels"),
    publicCustomModels: {},
    setPublicCustomModels: getUpdaterFunction(set, "publicCustomModels"),
    customModelInfo: undefined,
    setCustomModelInfo: getUpdaterFunction(set, "customModelInfo"),
    customModelDataset: undefined,
    setCustomModelDataset: getUpdaterFunction(set, "customModelDataset"),
    customModelTrainings: {},
    setCustomModelTrainings: getUpdaterFunction(set, "customModelTrainings"),
    customModelWorkflow: FrontendDisplayTemplateType.Custom,
    setCustomModelWorkflow: getUpdaterFunction(set, "customModelWorkflow"),
    customModelPredictions: {},
    setCustomModelPredictions: getUpdaterFunction(set, "customModelPredictions"),
  };
}

export function resetCustomModelEditorState(state: CustomModelEditorState) {
  state.setCustomModels({});
  state.setCustomModelTrainings({});
  state.setCustomModelId(undefined);
  state.setCustomModelInfo(undefined);
  state.setCustomModelDataset(undefined);
  state.setPublicCustomModels({});
  state.setCustomModelPredictions({});
}

export enum CustomModelTrainingEditorStatus {
  Default,
  Cancel,
}

export type CustomModelBackgroundTemplate = {
  name: string;
  caption: string;
  imageUrl: string;
};
