import {
  GENERATION_FRAME_BOTTOM_FILL_DARK,
  GENERATION_FRAME_TOP_BORDER_COLOR_DARK,
  GENERATION_FRAME_TOP_BORDER_COLOR_SELECTED_DARK,
} from "@/components/constants/colors";
import { LayerType } from "@/core/common/layers";
import {
  GenerationFramePointerLeaveEventName,
  GenerationFramePointerOverEventName,
} from "@/core/common/types";
import {
  coordsContainsPoint,
  shrinkCornerPoints,
  TCornerPoints,
} from "@/core/utils/geometry-utils";
import { fabric } from "fabric";

type TLineDescriptor = {
  o: fabric.Point;
  d: fabric.Point;
};

type TBBoxLines = {
  topline: TLineDescriptor;
  leftline: TLineDescriptor;
  bottomline: TLineDescriptor;
  rightline: TLineDescriptor;
};

export interface GenerationFrameOptions extends fabric.IRectOptions {
  id: string;
  name: string;
  description?: string;
}

// @ts-ignore
export class GenerationFrameObject extends fabric.Rect {
  static type = LayerType.GENERATION_FRAME;

  initialize(options: GenerationFrameOptions) {
    super.initialize({
      ...options,
      selectable: true,
      hasControls: true,
      evented: true,
      fill: "transparent",
      stroke: GENERATION_FRAME_TOP_BORDER_COLOR_DARK,
      borderColor: GENERATION_FRAME_TOP_BORDER_COLOR_SELECTED_DARK,
    });
    this.setControlVisible("tl", false);
    this.setControlVisible("bl", false);
    this.setControlVisible("tr", false);
    this.setControlVisible("br", false);
    this.setControlVisible("ml", false);
    this.setControlVisible("mb", false);
    this.setControlVisible("mr", false);
    this.setControlVisible("mt", false);
    this.setControlVisible("mtr", false);
    return this;
  }

  toObject(propertiesToInclude: string[] = []) {
    return super.toObject(propertiesToInclude);
  }

  toJSON(propertiesToInclude: string[] = []) {
    return super.toObject(propertiesToInclude);
  }

  static fromObject(options: GenerationFrameOptions, callback: Function) {
    return callback && callback(new fabric.GenerationFrameBottom(options));
  }

  containsPoint(
    point: fabric.Point,
    lines: TBBoxLines | undefined,
    absolute = false,
    calculate = false,
  ) {
    const coords = (this as any)._getCoords(absolute, calculate);
    const coordsExpanded = shrinkCornerPoints(coords, -5, -5);
    const coordsShrinked: TCornerPoints = shrinkCornerPoints(coords, 5, 5);
    const insideCoordsExpanded = coordsContainsPoint(coordsExpanded, point, lines);
    if (insideCoordsExpanded) {
      this.canvas?.fire(GenerationFramePointerOverEventName);
    } else {
      this.canvas?.fire(GenerationFramePointerLeaveEventName);
    }
    const insideCoordsShrinked = coordsContainsPoint(coordsShrinked, point, lines);
    return insideCoordsExpanded && !insideCoordsShrinked;
  }

  intersectsWithRect() {
    return false;
  }

  isContainedWithinRect() {
    return false;
  }
}

export class GenerationFrameTopObject extends GenerationFrameObject {
  static type = LayerType.GENERATION_FRAME_TOP;

  initialize(options: GenerationFrameOptions) {
    super.initialize({
      ...options,
      selectable: false,
      hasControls: false,
      evented: false,
      stroke: "transparent",
      strokeWidth: 1,
      borderColor: "transparent",
      fill: "transparent",
    });
    return this;
  }

  static fromObject(options: GenerationFrameOptions, callback: Function) {
    return callback && callback(new fabric.GenerationFrameTop(options));
  }
}

export class GenerationFrameBottomObject extends GenerationFrameObject {
  static type = LayerType.GENERATION_FRAME_BOTTOM;

  initialize(options: GenerationFrameOptions) {
    super.initialize({
      ...options,
      selectable: true,
      hasControls: true,
      evented: true,
      fill: GENERATION_FRAME_BOTTOM_FILL_DARK,
      stroke: GENERATION_FRAME_TOP_BORDER_COLOR_DARK,
      borderColor: GENERATION_FRAME_TOP_BORDER_COLOR_SELECTED_DARK,
    });
    this.setControlVisible("tl", false);
    this.setControlVisible("bl", false);
    this.setControlVisible("tr", false);
    this.setControlVisible("br", false);
    this.setControlVisible("ml", false);
    this.setControlVisible("mb", false);
    this.setControlVisible("mr", false);
    this.setControlVisible("mt", false);
    this.setControlVisible("mtr", false);
    return this;
  }

  static fromObject(options: GenerationFrameOptions, callback: Function) {
    return callback && callback(new fabric.GenerationFrameBottom(options));
  }
}

fabric.GenerationFrame = fabric.util.createClass(GenerationFrameObject, {
  type: GenerationFrameObject.type,
});
fabric.GenerationFrame.fromObject = GenerationFrameObject.fromObject;

fabric.GenerationFrameTop = fabric.util.createClass(GenerationFrameTopObject, {
  type: GenerationFrameTopObject.type,
});
fabric.GenerationFrameTop.fromObject = GenerationFrameTopObject.fromObject;

fabric.GenerationFrameBrush = fabric.util.createClass(GenerationFrameBottomObject, {
  type: GenerationFrameObject.type,
});

fabric.GenerationFrameBottom = fabric.util.createClass(GenerationFrameBottomObject, {
  type: GenerationFrameBottomObject.type,
});

fabric.GenerationFrameBottom.fromObject = GenerationFrameBottomObject.fromObject;

declare module "fabric" {
  namespace fabric {
    class GenerationFrame extends GenerationFrameObject {
      constructor(options: GenerationFrameOptions);
    }

    class GenerationFrameTop extends GenerationFrameTopObject {
      constructor(options: GenerationFrameOptions);
    }

    class GenerationFrameBottom extends GenerationFrameTopObject {
      constructor(options: GenerationFrameOptions);
    }

    class GenerationFrameBrush extends GenerationFrameObject {
      constructor(canvas: fabric.Canvas);
    }
  }
}
