export class DebounceBatchCall<Argument, Response> {
  private batchedArgs: Argument[] = [];

  // Now includes both resolve and reject for better error handling
  private pendingCalls: Array<{
    resolve: (value: Response | undefined) => void;
    reject: (reason?: any) => void;
  }> = [];

  private callback: (args: Argument[]) => Promise<Response>;
  private debounceSeconds: number;
  private timer: ReturnType<typeof setTimeout> | null = null;

  // New parameter with a default value
  private maxBatchSize: number;

  constructor({
    debounceSeconds = 10,
    callback,
    maxBatchSize = 100, // Default max batch size
  }: {
    debounceSeconds?: number;
    callback: (args: Argument[]) => Promise<Response>;
    maxBatchSize?: number;
  }) {
    this.callback = callback;
    this.debounceSeconds = debounceSeconds;
    this.maxBatchSize = maxBatchSize;
  }

  private async flush() {
    if (this.batchedArgs.length === 0) {
      return;
    }

    const argsToProcess = this.batchedArgs;
    const pending = this.pendingCalls;

    // Reset the batched arguments and pending calls for the next batch
    this.batchedArgs = [];
    this.pendingCalls = [];

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    try {
      const response = await this.callback(argsToProcess);

      // Resolve all pending promises with the batch response
      pending.forEach(({ resolve }) => resolve(response));
    } catch (error) {
      // Reject all pending promises with the encountered error
      pending.forEach(({ reject }) => reject(error));
    }
  }

  async call(arg: Argument): Promise<Response | undefined> {
    return new Promise<Response | undefined>((resolve, reject) => {
      this.batchedArgs.push(arg);
      this.pendingCalls.push({ resolve, reject });

      if (this.batchedArgs.length >= this.maxBatchSize) {
        // Immediately flush if max batch size is reached
        this.flush();
      } else if (!this.timer) {
        this.timer = setTimeout(() => this.flush(), this.debounceSeconds * 1000);
      }
    });
  }

  cancelPending(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    // Reject all pending promises to indicate cancellation
    this.pendingCalls.forEach(({ reject }) => reject(new Error("DebounceBatchCall cancelled")));

    this.batchedArgs = [];
    this.pendingCalls = [];
  }
}
