import { classNames } from "@/core/utils/classname-utils";

export const PrimaryButtonClassName =
  "flex flex-row items-center justify-center gap-2 px-3 py-2 rounded-md bg-lime-500 hover:bg-lime-400 active:bg-lime-600 select-none text-center text-zinc-900 font-semibold focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:outline-lime-100 transition-colors box-border cursor-pointer";

export const PrimaryButtonClassNameLoading = classNames(
  PrimaryButtonClassName,
  "bg-lime-600 text-zinc-500 cursor-wait hover:bg-lime-600 active:bg-lime-600",
);

export const PrimaryButtonClassNameDisabled = classNames(
  PrimaryButtonClassName,
  "bg-zinc-800 text-zinc-500 cursor-not-allowed hover:bg-zinc-800 active:bg-zinc-800",
);

export const PrimaryButtonClassNameError = classNames(
  PrimaryButtonClassName,
  "bg-red-500 cursor-not-allowed hover:bg-red-500 active:bg-red-500",
);

export const SecondaryButtonClassName =
  "flex flex-row items-center justify-center gap-2 bg-lime-900/20 p-2 rounded-md border border-solid border-lime-500 text-lime-400 hover:text-lime-500 active:text-lime-600 focus-visible:outline-none transition-colors select-none cursor-pointer shadow shadow-lime-500/20 box-border";

export const SecondaryButtonClassNameInactive = classNames(
  SecondaryButtonClassName,
  "bg-zinc-900 border-zinc-800 hover:border-lime-600 hover:text-lime-500 active:text-lime-600 text-zinc-100 focus:border-zinc-500 focus:hover:border-lime-500 shadow-none",
);

export const SecondaryButtonClassNameDisabled = classNames(
  SecondaryButtonClassName,
  "bg-zinc-900 text-zinc-700 border-zinc-800 cursor-not-allowed border-zinc-800 shadow-none",
);

export const CancelButtonClassNameActive = classNames(
  PrimaryButtonClassName,
  "bg-red-500 hover:bg-red-400 active:bg-red-500 cursor-pointer",
);

export const CancelButtonClassNameLoading = classNames(
  PrimaryButtonClassNameLoading,
  "bg-red-500 text-red-800 cursor-wait",
);

export const CancelButtonOutlineClassName = classNames(
  "flex flex-row items-center justify-center gap-2 bg-red-900/20 p-2 rounded-md border border-solid border-red-500 text-red-400 hover:text-red-500 active:text-red-600 focus-visible:outline-none transition-colors select-none cursor-pointer shadow shadow-red-500/20 box-border",
  "bg-red-900/20 border-red-500 text-red-500 hover:text-red-500 active:text-red-600",
);

export const TextButtonClassName =
  "flex flex-row items-center justify-center text-zinc-500 hover:text-lime-500 active:text-zinc-600 focus:text-zinc-300 transition-colors select-none cursor-pointer";

export const TextButtonClassNameDisabled =
  "flex flex-row items-center justify-center text-zinc-700 transition-colors select-none cursor-not-allowed";

export const CommonButtonClassName = "px-4 py-2 flex flex-row items-center justify-center gap-2";

export const OutputItemToolbarButtonClassName = classNames(
  SecondaryButtonClassNameInactive,
  "text-zinc-300 hover:bg-lime-500 active:bg-lime-300 hover:text-zinc-900 border-none hover:border-none shadow-xl cursor-pointer",
);

export const DropdownMenuItemClassName =
  "w-full flex flex-row items-center justify-start px-2 py-2 text-zinc-300 hover:text-lime-500 hover:outline-none select-none cursor-pointer transition-colors";
