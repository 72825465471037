import { InviteCodeContextProvider } from "@/components/auth/invite-code";
import { LoadingScreen } from "@/components/loading-screen";
import { NetworkStatusWrapper } from "@/components/network-status-error";
import { MessageToast } from "@/components/popup/message-toast";
import { editorContextStore, initEditorContextVanillaStore } from "@/contexts/editor-context";
import { ProjectInitSceneContextProvider } from "@/contexts/project-init-scene-context";
import { useAuthUpdateEffect } from "@/hooks/use-auth-update";
import { useFeatureFlagsEffect } from "@/hooks/use-featureFlags";
import { useCheckMobileEffect } from "@/hooks/use-mobile-context";
import { usePricingConfigUpdateEffect } from "@/hooks/use-pricing-config-update";
import { useTeamsUpdateEffect } from "@/hooks/use-teams-update";
import { useUserStripeSubscriptionUpdateEffect } from "@/hooks/use-user-stripe-subscription-update";
import { initObjects } from "@/objects";
import * as Tooltip from "@radix-ui/react-tooltip";
import React, { Suspense } from "react";
import PostHogPageView from "./analytics/ph-pageviews";

initObjects();
initEditorContextVanillaStore();

const MobileAppRoutes = React.lazy(async () => {
  const module = await import("@/components-mobile/app-routes");
  return { default: module.default };
});
const MobileEditorPopups = React.lazy(async () => {
  const module = await import("@/components-mobile/popup/popups");
  return { default: module.EditorPopups };
});
const AppRoutes = React.lazy(async () => {
  const module = await import("@/components/app-routes");
  return { default: module.default };
});
const DesktopEditorPopups = React.lazy(async () => {
  const module = await import("@/components/popup/popups");
  return { default: module.EditorPopups };
});

function Routes() {
  const isMobile = editorContextStore((state) => state.isMobile);

  return (
    <Suspense fallback={<LoadingScreen />}>
      {isMobile ? <MobileAppRoutes /> : <AppRoutes />}
    </Suspense>
  );
}

function Popups() {
  const isMobile = editorContextStore((state) => state.isMobile);

  return (
    <Suspense fallback={<LoadingScreen />}>
      {isMobile ? <MobileEditorPopups /> : <DesktopEditorPopups />}
    </Suspense>
  );
}

function App() {
  React.useEffect(() => {
    const handleWheelEvent = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault(); //prevent zoom
      }
      // prevent horizontal scrolling
      if (e.deltaX !== 0) {
        e.preventDefault();
      }
    };
    const handleKeyEvent = (e: KeyboardEvent) => {
      if (!e.ctrlKey) {
        return;
      }
      if (e.code === "Minus" || e.code === "Equal") {
        e.preventDefault();
      }
    };

    document.body.addEventListener("wheel", handleWheelEvent, { passive: false });
    window.addEventListener("keydown", handleKeyEvent);
    return () => {
      document.body.removeEventListener("wheel", handleWheelEvent);
      window.removeEventListener("keydown", handleKeyEvent);
    };
  }, []);

  useAuthUpdateEffect();

  useTeamsUpdateEffect();

  useCheckMobileEffect();

  useFeatureFlagsEffect();

  useUserStripeSubscriptionUpdateEffect();

  usePricingConfigUpdateEffect();

  return (
    <NetworkStatusWrapper>
      <Tooltip.Provider>
        <InviteCodeContextProvider>
          <ProjectInitSceneContextProvider>
            <Routes />
            <Popups />
            <MessageToast />
            <PostHogPageView />
          </ProjectInitSceneContextProvider>
        </InviteCodeContextProvider>
      </Tooltip.Provider>
    </NetworkStatusWrapper>
  );
}

export default App;
