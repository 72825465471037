import { editorContextStore } from "@/contexts/editor-context";
import { debugError } from "@/core/utils/print-utilts";
import { DefaultFeatureFlags, FeatureFlags } from "./featureFlags";

export class FeatureFlagController {
  private readonly storageKey = "FlairFeatureFlags";

  private flags: FeatureFlags = { ...DefaultFeatureFlags };
  private _isVIP: boolean = false;

  constructor() {
    try {
      this.loadFlags();
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  private loadFlags(): void {
    try {
      const storedFlags = localStorage.getItem(this.storageKey);
      if (storedFlags) {
        this.flags = { ...this.flags, ...JSON.parse(storedFlags) };
      }
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  private saveFlags(): void {
    try {
      localStorage.setItem(this.storageKey, JSON.stringify(this.flags));
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  public getFlag<K extends keyof FeatureFlags>(key: K): FeatureFlags[K] {
    try {
      return this.flags[key];
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  public setFlag<K extends keyof FeatureFlags>(key: K, value: FeatureFlags[K]): void {
    try {
      this.flags[key] = value;
      this.saveFlags();
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  public setFlags(flags: Partial<FeatureFlags>): void {
    try {
      Object.assign(this.flags, flags);
      editorContextStore.getState().setFeatureFlags(this.flags);
      this.saveFlags();
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  public setVIP(isVIP: boolean = false): void {
    try {
      this._isVIP = isVIP;
      editorContextStore.getState().setFeatureFlagVIP(this._isVIP);
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  public get isVIP(): boolean {
    try {
      return this._isVIP;
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  public isFeatureEnabled<K extends keyof FeatureFlags>(key: K): boolean {
    try {
      return !!this.flags[key];
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  public removeFlag<K extends keyof FeatureFlags>(key: K): void {
    try {
      delete this.flags[key];
      this.saveFlags();
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  public clearFlags(): void {
    try {
      this.flags = { ...DefaultFeatureFlags };
      editorContextStore.getState().setFeatureFlags(this.flags);
      this.saveFlags();
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  public clearVIP(): void {
    try {
      this._isVIP = false;
      editorContextStore.getState().setFeatureFlagVIP(this._isVIP);
    } catch (error) {
      debugError(error);
      throw error;
    }
  }

  public clear(): void {
    try {
      this.clearFlags();
      this.clearVIP();
    } catch (error) {
      debugError(error);
      throw error;
    }
  }
}
